import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import dayjs from "dayjs";
import { TimeNumberProps } from "./_type";
import { HOURS_IN_DAY } from "./_data";
import { COLOR } from "../../../utils/colors";
import { RobotTaskType } from "../../../hooks/robotTask/index.type";
import { chartLabel } from "../../../utils/styles";

export default function DPRate({
  startTime,
  endTime,
  data,
  isLoading
}: TimeNumberProps & { data: RobotTaskType[], isLoading: boolean }) {
  const time = dayjs.unix(endTime).diff(dayjs.unix(startTime), 'days')

  const filteredData = (data || []).filter((i) => i.from_location.includes('DP'))
  const totalDurationInMinute = filteredData.filter((i) => i && i.duration && i.duration.toString() !== '-1').map((item) => {
    const duration = item.duration.toString();

    const minutes = parseInt(duration.split(':')[0])
    const seconds = parseInt(duration.split(':')[1]) / 60

    const totalMinutes = minutes + seconds

    return totalMinutes
  }).reduce((a, b) => a + b, 0)

  const totalDuration = totalDurationInMinute / 60
  const totalCapacity = (HOURS_IN_DAY) * (time || 1);
  const busyTime = totalDuration
  const idleTime = totalCapacity - totalDuration
  
  const total = busyTime + idleTime
  const calcPercentage = (value: number) => parseFloat(((value / total) * 100).toFixed(2))

  return (
    <Grid item xs={12} md={6}>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          width: "100%",
          flex: "auto",
          border: `1px solid ${COLOR.gray[400]}`
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            my: '12px',
            ml: '14px',
            mr: '24px',
            gap: '6px'
          }}
        >
          <Typography className='font-poppins' variant="h6" sx={{ color: COLOR.black, fontSize: '16px' }}>
            DP Utilisation Rate
          </Typography>
          <Typography variant="h6" sx={{ color: "#9E9E9E", fontSize: '12px', mt: '-4px' }}>
            { time === 1 ? 'Yesterday' : time === 0 ? 'Today' : dayjs.unix(startTime).format('DD/MM/YY') + ' - ' + dayjs.unix(endTime).format('DD/MM/YY')}
          </Typography>
          {isLoading && (
            <CircularProgress />
          )}
          {data.length > 0 && (
            <PieChart
              skipAnimation
              series={[
                {
                  innerRadius: 45,
                  outerRadius: 80,
                  arcLabel: (item) => item.value > 0 ? `${calcPercentage(item.value)}%` : '',
                  data: [
                    { id: 0, value: busyTime, label: 'Busy', color: COLOR.graph.orange },
                    { id: 1, value: idleTime, label: 'Idle', color: COLOR.graph.green },
                    // { id: 2, value: error, label: 'Error', color: '#fd3636' },
                  ],
                },
              ]}
              sx={{ ...chartLabel }}
              height={200}
            />
          )}
          {!isLoading && data.length <= 0 && (
            <Box sx={{ width: '100%', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Typography variant="h6" sx={{ fontSize: '12px' }}>
                No Data to Display
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Grid>
  )
}