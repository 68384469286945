import { Breakpoint } from "@mui/material";

type VIEWPROPS = {
  PHONE: Breakpoint,
  TABLET: Breakpoint
  DESKTOP: Breakpoint
}

export const VIEW: VIEWPROPS = {
  PHONE: 'xs',
  TABLET: 'sm',
  DESKTOP: 'lg'
}