import { LiftJobType } from "./index.type"

export  const getStatusLabel = (status: string, lift: LiftJobType) => {
  switch (status) {
    case 'taking lift':
    case 'ROBOT_IN_LIFT':
      return `${lift.robot_name} taking lift from ${lift.from_floor} to ${lift.destination_floor}`
    
    case 'LIFT_BOOKING':
    case 'REQUESTING_LIFT':
    case 'ASSIGNED_LIFT':
    case 'LIFT_BOOKED':
      return `${lift.robot_name} request a lift from ${lift.from_floor} to ${lift.destination_floor}`;
      
    case 'LIFT_ARRIVE_REQUEST_FLOOR':
    case 'LIFT_DOOR_OPEN_REQUEST_FL':
      return `${lift.robot_name} request a lift from ${lift.from_floor} to ${lift.destination_floor}`

    case 'ROBOT_MOVING_INTO_LIFT':
      return `${lift.robot_name} moving into lift ${lift.from_floor}`

    case 'ROBOT_EXITED_LIFT':
    case 'REQUEST_COMPLETED':
      return `${lift.robot_name} exit the lift ${lift.destination_floor}`
    
    case 'ROBOT_EXIT_REQUEST_FL':
      return `${lift.robot_name} is unable to enter lift, returning to ${lift.from_floor} lift lobby`

    case 'ROBOT_STOP_ENTERING_LIFT_REQUEST_FL':
      return `Lift asks ${lift.robot_name} to exit lift at ${lift.from_floor}`
    
    case 'LIFT_ARRIVE_DESTINATION_FLOOR':
    case 'LIFT_DOOR_OPEN_DEST_FL':
      return `${lift.robot_name} arrived at ${lift.destination_floor}`;

    case 'ROBOT_MOVING_OUT_OF_LIFT':
      return `${lift.robot_name} is exiting lift at ${lift.destination_floor}`

    case 'CANNOT_ENTER_LIFT':
      return `${lift.robot_name} cannot enter the lift ${lift.from_floor}`

    case 'RETRY_REQUEST':
      return `Robot has failed to enter lift, recovery flow is initiated by ${lift.robot_name}`
    
    case 'REQUEST_CANCELLED':
      return `The Lift Request for ${lift.robot_name} has been cancelled`

    case 'REQUEST_FAILED':
      return `The Lift Request for ${lift.robot_name} has been failed`

    case 'CANNOT_EXIT_LIFT_AT_DEST_FL':
    case 'ROBOT_STOP_ENTERING_LIFT_DEST_FL':
      return `Lift asks ${lift.robot_name} to re-enter cabin at ${lift.destination_floor}`

    case 'RETRY_AT_DEST':
      return `Robot has failed to exit lift, recovery flow is restarted by the Lift at ${lift.destination_floor}`
    
    default:
      return `${lift.robot_name} want to take a lift from ${lift.from_floor} to ${lift.destination_floor}`
  }
}