import { Box, Button, Card, CardContent, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, Tab, Tabs, Typography } from "@mui/material";
import Calendar from "./_component/Calendar";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CalendarContentProps } from "./_component/Calendar.types";
import { Add, Close, DeleteOutlineTwoTone, EditOutlined, KeyboardArrowDown, TroubleshootOutlined } from "@mui/icons-material";
import { COLOR } from "../../utils/colors";
import JobForm from "./_component/Form";
import { TabContext, TabPanel } from "@mui/lab";
import ListView from "./_component/ListView";
import { months } from "./_function/common";
import ShowDataCard from "./_component/ShowDataCard";
import { useScheduledJobs } from "../../hooks/scheduled_jobs";
import { smoothTransition } from "../../utils/animate";
import { VIEW } from "../../utils/breakpoint";

export default function ScheduledJobPage() {
  const [viewMode, setViewMode] = useState<"calendar" | "list">("calendar")
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedJobId, setSelectedJobId] = useState('');
  const [selectedData, setSelectedData] = useState<CalendarContentProps>()
  const [isEdit, setIsEdit] = useState(false);
  const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [selectedRobot, setSelectedRobot] = useState('')
  const [isCreateNew, setIsCreateNew] = useState(false);

  const { data: dataSchedules, refetch: refetchJobs, isLoading: isLoadingJobs } = useScheduledJobs({
    start_time: dayjs(selectedDate).startOf("month").toDate(),
    end_time: dayjs(selectedDate).endOf("month").toDate()
  })

  useEffect(() => {
    if (!selectedDate || !dataSchedules) {
      return
    }
    refetchJobs()
  }, [selectedDate.getMonth(), selectedDate.getFullYear()])

  const getJobTypeText = (type: string, pickup_loc?: string) => {
    switch (type) {
      case 'delivery_job':
        return 'Deliver to'

      case 'adhoc_job':
        return 'Go to'

      case 'charging_job':
        return 'Charging to'

      case 'pick_up_job':
        return `From ${pickup_loc}`
      default:
        break;
    }
    return type
  }

  const getDefaultTitle = (data: CalendarContentProps) => {
    const typeText = getJobTypeText(data.job_type, data.sender_location)

    return typeText + ' ' + data.recipient_location
  }

  const scheduled_formatteds = dataSchedules ? dataSchedules.sort((a, b) => {
    return a.timestamp > b.timestamp ? 1 : -1;
  }).map((item, index) => {
    const tmp_id = item.id
    return {
      ...item,
      id: index + 1,
      key_id: tmp_id.toString(),
      title: item.title || getDefaultTitle(item),
      description: item.description || '-'
    }
  }) : []

  const findContents = () => {
    if (!selectedDate || !dataSchedules) {
      return []
    }
    const dateFormat = 'DD/MM/YYYY'
    return scheduled_formatteds.filter(
      (item) => dayjs.unix(item.timestamp).format(dateFormat) === dayjs(selectedDate).format(dateFormat)
    ) || []
  }

  // Set Edit to False when changed Date
  useEffect(() => {
    setIsEdit(false)
    setSelectedJobId('')
  }, [selectedDate])

  const onEdit = () => {
    setIsEdit(true)
  }

  const onCancelEdit = () => {
    setIsEdit(false)
  }

  const onSaveEdit = () => {
    setOpenConfirmUpdate(true)
  }

  const onSaveConfirmEdit = () => {
    setOpenConfirmUpdate(false)
    setIsEdit(false)
  }

  const onCancelConfirmEdit = () => {
    setOpenConfirmUpdate(false)
  }

  const onNewScheduleJob = () => {
    setIsCreateNew(true)
    setIsEdit(false)
  }

  const onCancelNew = () => {
    setIsCreateNew(false)
  }

  const onSubmitCreate = () => {
    setIsCreateNew(false)
    refetchJobs()
  }

  const onBeforeDelete = () => {
    setOpenConfirmDelete(true)
  }

  const onSubmitDelete = () => {
    setOpenConfirmDelete(false)
    setSelectedJobId('')
  }

  const onCancelDelete = () => {
    setOpenConfirmDelete(false)
  }

  const changeViewMode = (value: "calendar" | "list") => {
    setViewMode(value)
    setSelectedData(undefined)
  }

  const onSelectRow = (value: CalendarContentProps) => {
    setSelectedData(value)
  }

  const isShowSidebar = (viewMode === 'calendar' || isCreateNew || (viewMode === 'list' && !!selectedData)) as boolean

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '32px',
      padding: '16px',
    }}>
      <Box sx={(theme) => ({
        [theme.breakpoints.up(VIEW.PHONE)]: {
          flexDirection: 'column',
          paddingTop: '16px',
          paddingBottom: '16px',
          gap: '6px'
        },
        [theme.breakpoints.up(VIEW.TABLET)]: {
          flexDirection: 'row',
          alignItems: 'center',
          padding: '16px',
        },
        color: '#000',
        display: 'flex',
        justifyContent: 'space-between'
      })}>
        <Typography variant="h4" fontWeight={700}>
          Scheduled Jobs
        </Typography>
        <div>
          {!isCreateNew && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px' }}>
              {isLoadingJobs && (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
                  <Typography color={COLOR.gray[400]}>Loading...</Typography>
                  <CircularProgress size={14} />
                </div>
              )}
              <Button onClick={onNewScheduleJob} variant="contained">
                <Add />
                New Schedule Job
              </Button>
            </div>
          )}
        </div>
      </Box>
      <Box sx={(theme) => ({
        [theme.breakpoints.up(VIEW.PHONE)]: {
          flexDirection: 'column',
        },
        [theme.breakpoints.up(VIEW.TABLET)]: {
          flexDirection: 'row',
          paddingInline: '16px',
        },
        display: 'flex',
        gap: '16px'
      })}>
        <Box sx={(theme) => ({
          [theme.breakpoints.up(VIEW.TABLET)]: {
            maxWidth: isShowSidebar ? 'calc(100% - 300px)' : '100%'
          },
          flex: 'auto',
          width: '100%',
        })}>
          <TabContext value={viewMode}>
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '8px' }}>
                <Tabs
                  value={viewMode}
                  sx={{
                    '.MuiTabs-indicator': {
                      // display: 'none'
                      backgroundColor: COLOR.accent.green
                    },
                    '& .MuiTab-root': {
                      color: '#A0A0A0',
                      '&.Mui-selected': {
                        color: COLOR.accent.green,
                        fontWeight: 600,
                        // backgroundColor: '#00E58E',
                        // borderRadius: '30px'
                      }
                    }
                  }}
                  onChange={(_, value) => changeViewMode(value)} aria-label="basic tabs example">
                  <Tab label="Calendar" value="calendar" />
                  <Tab label="List" value="list" />
                </Tabs>
              </Box>
            </Card>
            <Card>
              <TabPanel value="calendar">
                <Calendar
                  selectedDate={selectedDate}
                  onSelectDate={(date, content) => {
                    setSelectedDate(date);
                  }}
                  dateContents={scheduled_formatteds}
                />
              </TabPanel>

              <TabPanel value="list">
                <ListView
                  dateContents={scheduled_formatteds}
                  onSelectRow={(data) => onSelectRow(data)}
                  year={selectedDate.getFullYear().toString()}
                  month={months[selectedDate.getMonth()]}
                  handleSelectDate={(date) => setSelectedDate(date)}
                />
              </TabPanel>
            </Card>
          </TabContext>

        </Box>
        {
          isShowSidebar && (
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up(VIEW.TABLET)]: {
                  maxWidth: '300px',
                },
                display: 'flex',
                flex: 'none',
                flexDirection: 'column',
                color: '#000',
                width: '100%'
              })}>
              <Card sx={{
                height: '100%',
                color: '#000'
              }}>
                <CardContent
                  sx={{
                    color: '#000'
                  }}
                >
                  {!isCreateNew && viewMode === 'calendar' && (
                    <>
                      <Typography>
                        {dayjs(selectedDate).format('DD MMM YYYY')}
                      </Typography>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        marginTop: '6px'
                      }}>
                        {findContents().map((content, index) => (
                          <ShowDataCard key={index} content={content} onClickContent={(content) => {
                            if (!content) {
                              return
                            }
                            setIsEdit(false)
                            if (selectedJobId === content.id + '-' + content.timestamp) {
                              setSelectedJobId('')
                              return
                            }
                            setSelectedJobId(content.id + '-' + content.timestamp)
                          }}
                            appendNode={
                              <div
                                style={{
                                  transform: `rotate(${content.id + '-' + content.timestamp === selectedJobId ? "180" : "0"}deg)`,
                                  transition: 'all',
                                  transitionDuration: '300ms'
                                }}
                              >
                                <KeyboardArrowDown />
                              </div>
                            }
                            selectedJobId={selectedJobId}
                            isEdit={isEdit}
                            onCancelEdit={() => onCancelEdit()}
                            onEdit={(data) => onEdit()}
                            onDelete={(data) => onBeforeDelete()}
                            onSaveEdit={() => onSaveEdit()}
                          />
                        ))}
                      </div>
                    </>
                  )}

                  {isCreateNew && (
                    <>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '8px'
                      }}>
                        <Typography sx={{
                          fontWeight: 600
                        }}>
                          Create New Schedule Job
                        </Typography>
                        <div style={{ cursor: 'pointer' }} onClick={onCancelNew}>
                          <Close />
                        </div>
                      </div>
                      <JobForm onCancelCreate={onCancelNew} onCreate={onSubmitCreate} />
                    </>
                  )}

                  {(viewMode === 'list' && selectedData !== undefined && !isCreateNew) && (
                    <ShowDataCard content={selectedData} onClickContent={(content) => {
                      if (!content) {
                        return
                      }
                      setIsEdit(false)
                      if (selectedJobId === content.id + '-' + content.timestamp) {
                        setSelectedJobId('')
                        return
                      }
                      setSelectedJobId(content.id + '-' + content.timestamp)
                    }}
                      // appendNode={
                      //   <div
                      //     style={{
                      //       transform: `rotate(${selectedData.jobDetail?.job_id === selectedJobId ? "180" : "0"}deg)`,
                      //       transition: 'all',
                      //       transitionDuration: '300ms'
                      //     }}
                      //   >
                      //     <KeyboardArrowDown />
                      //   </div>
                      // }
                      selectedJobId={selectedData.id + '-' + selectedData.timestamp}
                      isEdit={isEdit}
                      onCancelEdit={() => onCancelEdit()}
                      onEdit={(data) => onEdit()}
                      onDelete={(data) => onBeforeDelete()}
                      onSaveEdit={() => onSaveEdit()}
                    />
                  )}

                  {!isCreateNew && viewMode === 'calendar' && findContents().length === 0 && (
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '60vh',
                      flexDirection: 'column',
                      gap: '6px'
                    }}>
                      <div style={{
                        color: COLOR.gray[400]
                      }}>
                        <TroubleshootOutlined fontSize="large" />
                      </div>
                      <Typography fontSize={12} color={COLOR.gray[400]}>No events on this date yet</Typography>
                      {
                        selectedDate && dayjs(selectedDate).isAfter(dayjs().startOf('day')) && (
                          <Button size="small" onClick={onNewScheduleJob} variant="outlined">
                            <Add />
                            New Schedule Job
                          </Button>
                        )
                      }
                    </div>
                  )}
                </CardContent>
              </Card>
            </Box>
          )
        }
      </Box>

      <Dialog maxWidth={'lg'} open={openConfirmUpdate} onClose={() => setOpenConfirmUpdate(false)}>
        <DialogTitle fontSize={16} fontWeight={500}>Save Edited Schedule Job</DialogTitle>
        <DialogContent>
          <RadioGroup
          >
            <FormControlLabel value="this_event" control={<Radio />} label="This Event" />
            <FormControlLabel value="this_and_following" control={<Radio />} label="This and following events" />
            <FormControlLabel value="all" control={<Radio />} label="All events" />
          </RadioGroup>

          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            marginTop: '8px',
            gap: '4px'
          }}>
            <Button onClick={() => onCancelConfirmEdit()}>Cancel</Button>
            <Button onClick={() => onSaveConfirmEdit()} variant="contained" disableElevation>Save</Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog maxWidth={'lg'} open={openConfirmDelete} onClose={() => setOpenConfirmDelete(false)}>
        <DialogTitle fontSize={16} fontWeight={500}>Delete Schedule Job</DialogTitle>
        <DialogContent>
          <RadioGroup
          >
            <FormControlLabel value="this_event" control={<Radio />} label="This Event" />
            <FormControlLabel value="this_and_following" control={<Radio />} label="This and following events" />
            <FormControlLabel value="all" control={<Radio />} label="All events" />
          </RadioGroup>

          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            marginTop: '8px',
            gap: '4px'
          }}>
            <Button onClick={onCancelDelete}>Cancel</Button>
            <Button onClick={onSubmitDelete} variant="contained" color="error" disableElevation>Delete</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div >
  )
}