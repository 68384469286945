import { Add, ArrowForwardIos, Close, Delete, Edit, SmartToy } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Paper, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteTimeBasedStandByPointMutation, useRobotDetail, useSetTimeBasedStandByPointMutation, useTimebasedStandbyPoint, useUpdateTimeBasedStandByPointMutation } from "../../../hooks/robotDetail";
import { COLOR } from "../../../utils/colors";
import swiftbot from '../../../assets/swiftbot.png';
import { useEffect, useState } from "react";
import { useListMap } from "../../../hooks/map";
import { useRobotStatus } from "../../../hooks/robot";
import { defaultHeaderStyle, defaultTableCellStyle } from "../../robots/components/v2/_style";
import { dummyRobotTableData } from "../../robots/components/v2/_const";
import dayjs from "dayjs";
import { TimeBasedStandbyResponseType } from "../../../hooks/robotDetail/index.type";
import { FALLBACK_DATE, weekdays } from "../../scheduledJob/_function/common";
import { useLocationPoints } from "../../../hooks/location";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import { VIEW } from "../../../utils/breakpoint";
import { paginate } from "../../../utils/utils";

const softwareVersion = '9.5.1.12'
const SLAType = 'Enterprise'
const standByLocation = 'DP'

export default function RobotDetailv2() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: dataRobots, isLoading, refetch: refetchRobotData } = useRobotDetail({ robot_id: id })
  const robot = dataRobots && dataRobots.data.length > 0 ? dataRobots.data[0] : undefined
  const waitDuration = undefined
  const [tabValue, setTabValue] = useState('map')
  const handleTabChange = (value: string) => {
    setTabValue(value)
  }

  const { data: dataLocation, refetch: refetchLocationData } = useLocationPoints('waypoint', { pageSize: 100 })
  const locations = dataLocation && dataLocation.data ? dataLocation.data : []

  const [selectedDefaultLocation, setSelectedDefaultLocation] = useState('')
  const [selectedTimeBased, setSelectedTimeBased] = useState<TimeBasedStandbyResponseType>()
  const [showStandbyPoint, setShowStandbyPoint] = useState(false)
  const [showTimeBased, setShowTimeBased] = useState(false)
  const [selectedDays, setSelectedDays] = useState<string[]>([])
  const [selectedStartTime, setSelectedStartTime] = useState<Date>()
  const [selectedEndTime, setSelectedEndTime] = useState<Date>()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);


  const { trigger: submitTimeBaseStandbyPoint, isLoading: isSubmitStandbyPoint } = useSetTimeBasedStandByPointMutation({
    onSuccess: () => {
      onCloseCreateStandby()
      refetchRobotData()
      refetchLocationData()
      setShowStandbyPoint(false)
      setSelectedDefaultLocation('')
      setSelectedDays([])
      setSelectedStartTime(undefined)
      setSelectedEndTime(undefined)
      setSelectedTimeBased(undefined)
      refetchTimeBased()
    }
  })

  const { trigger: updateTimeBaseStandbyPoint, isLoading: isUpdating } = useUpdateTimeBasedStandByPointMutation(selectedTimeBased ? selectedTimeBased.id : '', {
    onSuccess: () => {
      onCloseCreateStandby()
      refetchRobotData()
      refetchLocationData()
      setShowStandbyPoint(false)
      setSelectedDefaultLocation('')
      setSelectedDays([])
      setSelectedStartTime(undefined)
      setSelectedEndTime(undefined)
      setSelectedTimeBased(undefined)
      refetchTimeBased()
    }
  })

  const { trigger: deleteTimeBaseStandbyPoint, isLoading: isDeleting } = useDeleteTimeBasedStandByPointMutation(selectedTimeBased ? selectedTimeBased.id : '', {
    onSuccess: () => {
      onCloseCreateStandby()
      refetchRobotData()
      refetchLocationData()
      setShowStandbyPoint(false)
      setSelectedDefaultLocation('')
      setSelectedDays([])
      setSelectedStartTime(undefined)
      setSelectedEndTime(undefined)
      setSelectedTimeBased(undefined)
      refetchTimeBased()
      setShowDeleteDialog(false)
    }
  })

  const [rowsPerPageMap, setRowsPerPageMap] = useState(10)
  const [pageMap, setPageMap] = useState(1)

  const { data: dataMaps, refetch: refetchMap } = useListMap({ enabled: !!robot })
  const { data: robotStatus } = useRobotStatus({ enabled: !!robot })

  const currentRobotStatus = robotStatus && robot ? robotStatus.find((item) => item.robot_id === id) : undefined
  const filteredMaps = dataMaps && currentRobotStatus ? dataMaps.data.filter((item) => item.map_uuid === (currentRobotStatus as any).map_uuid || item.map_uuid === (currentRobotStatus as any).layout_uuid) : []
  const maps = paginate(filteredMaps, rowsPerPageMap, pageMap)
  const currentMap = maps && maps.length > 0 ? maps[0].building + ' ' + maps[0].map_name_display : '-'

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)

  const { data: dataTimeBased, refetch: refetchTimeBased } = useTimebasedStandbyPoint({
    robot_id: id,
    enabled: !!robot && tabValue === 'time-based',
    pageSize: rowsPerPage,
    page
  })

  const timebasedStandbyPoints = dataTimeBased && dataTimeBased.data ? dataTimeBased.data : []
  const timebasedTotalData = dataTimeBased && dataTimeBased.num_pages ? dataTimeBased.num_pages : 1

  const onEditTimeBased = (timeBased: TimeBasedStandbyResponseType) => {
    setSelectedTimeBased(timeBased)
    setShowStandbyPoint(true)
    setShowTimeBased(true)

    setSelectedDefaultLocation(timeBased.location_name)
    setSelectedStartTime(dayjs(`2024-01-01 ${timeBased.start_time}`).toDate())
    setSelectedEndTime(dayjs(`2024-01-01 ${timeBased.end_time}`).toDate())

    const days: string[] = []
    timeBased.days?.map((day) => {
      const data = weekdays.find((d) => d.toLowerCase().substring(0, 3) === day.toLowerCase())

      if (!!data) {
        days.push(data)
      }
    })

    setSelectedDays(days)
  }

  const onCloseCreateStandby = () => {
    setShowStandbyPoint(false)
    setSelectedDefaultLocation('')
    setSelectedDays([])
    setSelectedStartTime(undefined)
    setSelectedEndTime(undefined)
  }

  const toggleDay = (day: string) => {
    const tmpRes = [...selectedDays];
    // Already Exist
    if (selectedDays.includes(day)) {
      // Then Remove it
      const findIndex = tmpRes.findIndex((item) => item === day);

      if (findIndex < 0) {
        return
      }

      tmpRes.splice(findIndex, 1);
      setSelectedDays(tmpRes)
      return
    }

    tmpRes.push(day)
    setSelectedDays(tmpRes)
  }

  const onSubmitTimeBasedStandbyPoint = () => {
    if (!id || !selectedDefaultLocation || !selectedEndTime || !selectedStartTime || selectedDays.length === 0) {
      return
    }

    if (!selectedTimeBased) {
      submitTimeBaseStandbyPoint({
        robot_id: id,
        location_name: selectedDefaultLocation,
        days: selectedDays.map((day) => {
          return day.charAt(0).toUpperCase() + day.substring(1, 3);
        }),
        start_time: dayjs(selectedStartTime).format("HH:mm:ss"),
        end_time: dayjs(selectedEndTime).format("HH:mm:ss")
      })

      return
    }

    updateTimeBaseStandbyPoint({
      location_name: selectedDefaultLocation,
      days: selectedDays.map((day) => {
        return day.charAt(0).toUpperCase() + day.substring(1, 3);
      }),
      start_time: dayjs(selectedStartTime).format("HH:mm:ss"),
      end_time: dayjs(selectedEndTime).format("HH:mm:ss")
    })

  }

  const isDisableTimeBased = !selectedDefaultLocation || !selectedStartTime || !selectedEndTime || selectedDays.length === 0

  const onAddStandbyPoint = () => {
    setShowStandbyPoint(true)
    setShowTimeBased(true)
    setSelectedTimeBased(undefined)
  }

  const onShowDeleteDialog = (timeBased: TimeBasedStandbyResponseType) => {
    setSelectedTimeBased(timeBased)
    setShowDeleteDialog(true)
  }

  const onDeleteTimeBased = () => {
    if (!selectedTimeBased || !id) {
      return
    }
    setShowDeleteDialog(false)
    deleteTimeBaseStandbyPoint({})
  }

  const handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const handleChangePageMap: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void = (event, newPage) => {
    setPageMap(newPage + 1);
    console.log('HANDSXX', pageMap, newPage);

  };

  const handleChangeRowsPerPageMap: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    setRowsPerPageMap(parseInt(event.target.value));
    setPageMap(1);
  };

  useEffect(() => {
    refetchTimeBased()
  }, [page, rowsPerPage])

  useEffect(() => {
    refetchMap()
    console.log('HANDS;,');
    
  }, [pageMap, rowsPerPageMap])
  console.log('HANDS', pageMap, maps);


  return (
    <Box
      width={"95%"}
      height={"100%"}
      maxHeight={"100%"}
      sx={{ margin: "auto", mt: 2, pb: '36px' }}
    >
      <Stack
        direction="column"
        alignContent={"start"}
        justifyContent={"start"}
        sx={{
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          color: "black",
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ArrowForwardIos fontSize="small" sx={{ color: "##262424" }} />}
        >
          <IconButton onClick={() => navigate("/robot")} sx={{ color: "##262424" }}>
            <SmartToy />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography sx={{ fontSize: "18px", fontWeight: 550, color: "##262424" }}>{robot?.robot_name}</Typography>
            {robot && (
              <div style={{ width: '10px', height: '10px', borderRadius: '999px', backgroundColor: currentRobotStatus && currentRobotStatus.is_online ? COLOR.accent.green : COLOR.accent.offline }}></div>
            )}
          </Box>
        </Breadcrumbs>
      </Stack>

      <Paper elevation={2}
        sx={(theme) => ({
          [theme.breakpoints.up(VIEW.PHONE)]: {
            paddingInline: '16px',
            paddingTop: '24px',
            paddingBottom: '24px',
          },
          [theme.breakpoints.up(VIEW.TABLET)]: {
            paddingTop: '46px',
            marginTop: '24px',
            paddingInline: '51px',
            paddingBottom: '46px'
          },
        })}>
        <Box sx={(theme) => ({
          [theme.breakpoints.up(VIEW.PHONE)]: {
            flexDirection: 'column-reverse',
            gap: '16px'
          },
          [theme.breakpoints.up(VIEW.DESKTOP)]: {
            flexDirection: 'row',
            gap: '86px'

          },
          display: 'flex',
          alignContent: 'center',
        })}>
          <Box sx={(theme) => ({
            [theme.breakpoints.up(VIEW.PHONE)]: {
              width: '100px'
            },
            [theme.breakpoints.up(VIEW.DESKTOP)]: {
              width: '200px'
            },
          })}>
            <img src={swiftbot} alt="robot-image" style={{
              width: '100%',
              objectFit: 'contain',
              objectPosition: 'top'
            }} />
          </Box>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up(VIEW.PHONE)]: {
                gap: '16px'
              },
              [theme.breakpoints.up(VIEW.DESKTOP)]: {
                gap: '42px'
              },
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
            })}>
            <Typography variant="h5" fontWeight={700}>Robot Details</Typography>
            {robot && (
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.up(VIEW.PHONE)]: {
                    flexDirection: 'column',
                    gap: '16px'

                  },
                  [theme.breakpoints.up(VIEW.DESKTOP)]: {
                    flexDirection: 'row',
                    gap: '86px'

                  },
                  display: 'flex',
                  alignContent: 'center',
                })}>
                <Box>
                  <Table size="small" aria-label="events">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={(theme) => ({ [theme.breakpoints.up(VIEW.DESKTOP)]: { minWidth: '200px' }, border: `1px solid ${COLOR.gray[300]} !important`, })}>Serial Number</TableCell>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important` }}>{robot.robot_serial_number}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important` }}>MAC Address</TableCell>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important` }}>{robot.robot_mac}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important` }}>Software Version</TableCell>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important` }}>{softwareVersion}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important` }}>SLA Type</TableCell>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important` }}>{SLAType}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box sx={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}>
                  <Table size="small" sx={{ height: 'auto' }} aria-label="events">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={(theme) => ({ [theme.breakpoints.up(VIEW.DESKTOP)]: { minWidth: '200px' }, border: `1px solid ${COLOR.gray[300]} !important`, })}>Standby Location</TableCell>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important`, paddingInline: '2px' }}>
                          <Select defaultValue={'DP'} sx={{ width: '100%', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }} size="small">
                            {locations.map((location, index) => (
                              <MenuItem
                                key={index}
                                value={location.location_name}
                              >
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', paddingTop: '1px', paddingBottom: '1px' }}>
                                  <span>{location.location_name}</span>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important` }}>Wait Duration for Failed Delivery</TableCell>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important`, paddingInline: '2px' }}>
                          <Select
                            defaultValue={'indefinitely'}
                            sx={{
                              width: '100%',
                              boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }
                            }} size="small">
                            <MenuItem
                              value={'15min'}
                            >
                              <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', paddingTop: '1px', paddingBottom: '1px' }}>
                                <span>15 mins</span>
                              </div>
                            </MenuItem>
                            <MenuItem
                              value={'indefinitely'}
                            >
                              <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', paddingTop: '1px', paddingBottom: '1px' }}>
                                <span>Indefinitely</span>
                              </div>
                            </MenuItem>
                          </Select>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important` }}>Current Map</TableCell>
                        <TableCell sx={{ border: `1px solid ${COLOR.gray[300]} !important` }}>{currentMap}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ marginTop: '48px' }}>
          <Tabs
            value={tabValue}
            onChange={(_, value) => handleTabChange(value)}
            sx={{
              '.MuiTabs-indicator': {
                backgroundColor: ''
              },
              '& .MuiTab-root': {
                color: '#A0A0A0',
                fontSize: '14px',
                borderBottom: '1px solid #A0A0A'
              },
              borderBottom: `1px solid ${COLOR.gray[300]}`
            }}>
            <Tab label="Map" value={'map'} />
            <Tab label="Time Base Standby Config" value={'time-based'} />
          </Tabs>

          {tabValue === 'map' && (
            <Box sx={(theme) => ({
              [theme.breakpoints.up(VIEW.PHONE)]: {
                mt: '16px'
              },
              mt: '42px'
            })}>
              <TableContainer sx={{ alignItems: "center", overflowX: "auto" }}>
                <Table stickyHeader sx={{ border: `1px solid ${COLOR.gray[300]} !important`, borderRadius: '10px', overflow: 'hidden' }}>
                  <TableHead sx={{ width: "100%", margin: "auto" }}>
                    <TableRow sx={{ width: "100%", margin: "auto" }}>
                      <TableCell
                        align="center"
                        sx={{
                          ...defaultHeaderStyle
                        }}>
                        Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...defaultHeaderStyle
                        }}>
                        Level
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...defaultHeaderStyle
                        }}>
                        Location
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...defaultHeaderStyle
                        }}>
                        Preview
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {maps && maps.length > 0 && maps.map((data, index) => (
                      <TableRow
                        key={index}
                        sx={{ backgroundColor: "white", '&:hover': { backgroundColor: '#f5f5f5' } }}
                      >
                        <TableCell align="center" sx={defaultTableCellStyle}>
                          {data.building + ' ' + data.map_name_display}
                        </TableCell>
                        <TableCell align="center" sx={defaultTableCellStyle}>
                          {data.map_name_display}
                        </TableCell>
                        <TableCell align="center" sx={defaultTableCellStyle}>
                          {dummyRobotTableData.site_address}
                        </TableCell>
                        <TableCell align="center" sx={defaultTableCellStyle}>
                          {data && data.map_image ? (
                            <img src={data.map_image} alt="robot-image" style={{
                              width: '259px',
                              height: '228px',
                              objectFit: 'scale-down',
                              objectPosition: 'center'
                            }} />
                          ) : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={filteredMaps.length || 0}
                rowsPerPage={rowsPerPageMap}
                page={pageMap > 0 ? pageMap - 1 : 0}
                onPageChange={handleChangePageMap}
                onRowsPerPageChange={handleChangeRowsPerPageMap}
                sx={{ flexShrink: 0, boxShadow: "none", mt: '-1px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', borderInline: `1px solid ${COLOR.gray[300]}`, borderBottom: `1px solid ${COLOR.gray[300]}` }}
              />
            </Box>
          )}

          {tabValue === 'time-based' && (
            <Box>
              <Box sx={{ display: 'flex', mt: '16px', justifyContent: 'end' }}>
                <Button onClick={() => onAddStandbyPoint()} variant="outlined" startIcon={<Add />}>Create New</Button>
              </Box>
              <Box sx={{ mt: '16px' }}>
                {timebasedStandbyPoints.length > 0 && (
                  <>
                    <TableContainer sx={{ alignItems: "center", overflowX: "auto" }}>
                      <Table stickyHeader sx={{ border: `1px solid ${COLOR.gray[300]} !important`, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', overflow: 'hidden' }}>
                        <TableHead sx={{ width: "100%", margin: "auto" }}>
                          <TableRow sx={{ width: "100%", margin: "auto" }}>
                            <TableCell
                              align="center"
                              sx={{
                                ...defaultHeaderStyle
                              }}>
                              Standby Location
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                ...defaultHeaderStyle
                              }}>
                              Standby Time
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                ...defaultHeaderStyle
                              }}>
                              Standby Days
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                ...defaultHeaderStyle
                              }}>
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {timebasedStandbyPoints.map((item, index) => (
                            <TableRow
                              key={index}
                              sx={{ cursor: 'pointer', backgroundColor: "white", '&:hover': { backgroundColor: '#f5f5f5' } }}
                            >
                              <TableCell align="center" sx={defaultTableCellStyle}>
                                {item.location_name}
                              </TableCell>
                              <TableCell align="center" sx={defaultTableCellStyle}>
                                {dayjs(FALLBACK_DATE + ' ' + item.start_time).format('hh:mma')} - {dayjs(FALLBACK_DATE + ' ' + item.end_time).format('hh:mma')}
                              </TableCell>
                              <TableCell align="center" sx={defaultTableCellStyle}>
                                {item.days.join(', ')}
                              </TableCell>
                              <TableCell align="center" sx={defaultTableCellStyle}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                                  <IconButton onClick={() => onEditTimeBased(item)}>
                                    <Edit />
                                  </IconButton>
                                  <IconButton onClick={() => onShowDeleteDialog(item)}>
                                    <Delete />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 20]}
                      component="div"
                      count={timebasedTotalData || 0}
                      rowsPerPage={rowsPerPage}
                      page={page > 0 ? page - 1 : 0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      sx={{ flexShrink: 0, boxShadow: "none", mt: '-1px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', borderInline: `1px solid ${COLOR.gray[300]}`, borderBottom: `1px solid ${COLOR.gray[300]}` }}
                    />
                  </>
                )}
                {timebasedStandbyPoints.length === 0 && (
                  <Box sx={{ display: 'flex', mt: '16px', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography sx={{ color: COLOR.accent.offline }}>No data available</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Paper>

      {showStandbyPoint && showTimeBased && (
        <Dialog open={showTimeBased} onClose={() => onCloseCreateStandby()}>
          <DialogContent>
            <Box sx={(theme) => ({ [theme.breakpoints.up(VIEW.DESKTOP)]: { minWidth: '400px' }, flex: 'none' })}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
                <div>
                  <Typography fontWeight={600}>
                    {`${selectedTimeBased ? 'Edit' : 'Create'} Time-based Standby Point`}
                  </Typography>
                </div>
                <div style={{ cursor: 'pointer' }}
                  onClick={() => onCloseCreateStandby()}
                >
                  <Close />
                </div>
              </div>
              <div style={{ marginTop: '8px' }}>
                <div>
                  <Typography fontSize={'12px'}>New Time-based Standby Point</Typography>
                  <Select placeholder="Select Standby Point" sx={{ width: '100%' }} size="small" value={selectedDefaultLocation} onChange={(e) => setSelectedDefaultLocation(e.target.value)}>
                    {locations.map((location, index) => (
                      <MenuItem
                        key={index}
                        value={location.location_name}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', paddingTop: '4px', paddingBottom: '4px' }}>
                          <span>{location.location_name}</span>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <div style={{ marginTop: '8px' }}>
                    <Typography fontSize={'12px'}>Start Time</Typography>
                    <TimePicker
                      sx={{
                        '& .MuiInputBase-input': {
                          paddingTop: '8.5px',
                          paddingBottom: '8.5px',
                        },
                        width: '100%'
                      }}
                      value={selectedStartTime ? dayjs(selectedStartTime) : undefined}
                      onChange={(val) => setSelectedStartTime(val?.toDate())}
                    />
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <Typography fontSize={'12px'}>End Time</Typography>
                    <TimePicker
                      sx={{
                        '& .MuiInputBase-input': {
                          paddingTop: '8.5px',
                          paddingBottom: '8.5px',
                        },
                        width: '100%'
                      }}
                      value={selectedEndTime ? dayjs(selectedEndTime) : undefined}
                      onChange={(val) => setSelectedEndTime(val?.toDate())}
                    />
                  </div>
                </LocalizationProvider>

                <div style={{ marginTop: '8px' }}>
                  <Typography fontSize={'12px'}>Days</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '4px',
                      alignItems: 'center',
                    }}
                  >
                    {weekdays.map((day, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 'auto',
                          width: '32px',
                          height: '32px',
                          backgroundColor: selectedDays.includes(day) ? COLOR.accent.green : COLOR.gray[200],
                          color: selectedDays.includes(day) ? COLOR.neutral[50] : COLOR.gray[600],
                          borderRadius: '999px',
                          aspectRatio: '1/1',
                          userSelect: 'none',
                          cursor: 'pointer'
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          toggleDay(day)
                        }}
                      >
                        <Typography fontSize={12} sx={{ textTransform: 'capitalize' }}>{day.substring(0, 3)}</Typography>
                      </div>
                    ))}
                  </div>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '12px' }}>
                  <Button onClick={() => onCloseCreateStandby()} variant="outlined">Cancel</Button>
                  <LoadingButton
                    loading={isSubmitStandbyPoint || isUpdating}
                    onClick={onSubmitTimeBasedStandbyPoint}
                    disabled={isDisableTimeBased}
                    variant="contained">
                    Save
                  </LoadingButton>
                </div>
              </div>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {showDeleteDialog && (
        <Dialog maxWidth={'sm'} fullWidth={true} open={showDeleteDialog} onClose={() => {
          setShowDeleteDialog(false)
          setSelectedTimeBased(undefined)
        }}>
          <DialogTitle fontSize={16} fontWeight={500}>Are you sure want to delete?</DialogTitle>
          <DialogContent>
            {selectedTimeBased && (
              <div>
                <div>
                  <Typography fontSize={'12px'}>Standby Point</Typography>
                  <Typography fontSize={'16px'}>
                    {selectedTimeBased.location_name}
                  </Typography>
                </div>
                <div style={{ marginTop: '6px' }}>
                  <Typography fontSize={'12px'}>Time</Typography>
                  <Typography fontSize={'16px'}>
                    {dayjs(FALLBACK_DATE + ' ' + selectedTimeBased.start_time).format('hh:mma')} - {dayjs(FALLBACK_DATE + ' ' + selectedTimeBased.end_time).format('hh:mma')}
                  </Typography>
                </div>
                <div>
                  <Typography fontSize={'12px'}>Days</Typography>
                  <Typography fontSize={'16px'}>
                    {selectedTimeBased.days.length === 0 ? "Everyday" : selectedTimeBased.days.map((day) => day || '').join(', ')}
                  </Typography>
                </div>
              </div>
            )}
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              marginTop: '12px',
              gap: '4px'
            }}>
              <Button onClick={() => {
                setShowDeleteDialog(false)
                setSelectedTimeBased(undefined)
              }}>Cancel</Button>
              <Button onClick={() => onDeleteTimeBased()} variant="contained" color="error" disableElevation>Yes, Delete</Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}