import { Chip, ChipPropsColorOverrides, ChipPropsVariantOverrides, SxProps, Theme } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { includesIn } from "../routes/scheduledJob/_function/common";
import { COLOR } from "../utils/colors";

type ColorType = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export default function StatusChip ({
  children,
  variant,
  color,
  size = 'medium'
} : {
  children ?: string,
  variant?: OverridableStringUnion<'filled' | 'outlined', ChipPropsVariantOverrides>,
  color?: OverridableStringUnion<ColorType, ChipPropsColorOverrides>,
  size?: 'medium' | 'small'
}){

  const getStyle = (): SxProps<Theme> => {
    if(color === 'info'){
      return { backgroundColor: COLOR.backgroundOutline.blue, color: COLOR.accent.blue, border: `1px solid ${COLOR.accent.blue}`, textTransform: 'capitalize' }
    }
    if(color === 'success'){
      return { backgroundColor: COLOR.backgroundOutline.green, color: COLOR.accent.green, border: `1px solid ${COLOR.accent.green}`, textTransform: 'capitalize' }
    }
    if(color === 'error'){
      return { backgroundColor: COLOR.backgroundOutline.danger, color: COLOR.accent.danger, border: `1px solid ${COLOR.accent.danger}`, textTransform: 'capitalize' }
    }
    return { backgroundColor: COLOR.backgroundOutline.offline, color: COLOR.gray[500], border: `1px solid ${COLOR.accent.offline}`, textTransform: 'capitalize' }
  }
  return (
    <Chip label={children} variant={variant} color={color} size={size} sx={{ ...getStyle()}} />
  )
}

export const statusColorMap = (status: string): ColorType => {
  const lowerStatus = status.toLowerCase()
  if (!lowerStatus) {
    return 'default'
  }

  if (includesIn(lowerStatus, ['complete', 'done', 'delivered'])) {
    return 'success'
  }

  if(includesIn(lowerStatus, ['failed', 'cancel'])) {
    return 'error'
  }

  if (includesIn(lowerStatus, ['returning', 'progress', 'assigning', 'delivering', 'assign', 'start', 'received'])) {
    return 'info'
  }

  return 'default'
}