export const getJobTypeLabel = (value: string) => {
  switch (value) {
    case 'delivery_job':
      return 'Delivery Job'
      break;

    default:
      break;
  }

  return value
}

export const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const months = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'];

export const DEFAULT_DATEFORMAT = 'DD/MM/YYYY'
export const FALLBACK_DATE = '2000/01/01'

export const sumOf = (arr: number[]) => arr.reduce((a, b) => a + b, 0)

export const includesIn = (text: string, list: string[]) => {
  return !!list.find((item) => text.includes(item))
}
