import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import dayjs from "dayjs";
import { TimeNumberProps } from "./_type";
import { useLiftStats } from "../../../hooks/lift";
import { useEffect } from "react";
import { COLOR } from "../../../utils/colors";
import { sumOf } from "../../scheduledJob/_function/common";

export default function FirstAttemptLiftRate({
  startTime,
  endTime
}: TimeNumberProps) {
  const time = dayjs.unix(endTime).diff(dayjs.unix(startTime), 'days')
  
  const { data: dataLift, isLoading, refetch} = useLiftStats({
    start_time: startTime,
    end_time: dayjs.unix(endTime).add(1, 'day').unix()
  })

  const rawLiftStats = dataLift ? Object.values(dataLift.daily_stats) : []
  const liftStats = rawLiftStats.filter((data) => data.start_time >= startTime && data.start_time <= endTime)
  const dayArray = liftStats.map((stat) => dayjs.unix(stat.start_time).format('DD/MM/YY'))
  const liftStatRates = [
    {
      name: 'No Retry',
      color: COLOR.graph.blue,
      value: liftStats.map((stat) => stat.no_retries),
    }, {
      name: '>= 1 Retry',
      color: COLOR.graph.red,
      value: liftStats.map((stat) => stat.retries),
    }
  ]

  const totalNoRetries = sumOf(liftStats.map((stat) => stat.no_retries))
  const totalRetries = sumOf(liftStats.map((stat) => stat.retries))
  const totalTrips = totalRetries + totalNoRetries
  
  useEffect(() => {
    refetch()
  }, [startTime, endTime])

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          width: "100%",
          flex: "auto",
          border: `1px solid ${COLOR.gray[400]}`
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            my: '8px',
            ml: '14px',
            mr: '8px',
            gap: '6px'
          }}
        >
          <Typography className='font-poppins' variant="h6" sx={{ color: COLOR.black, fontSize: '16px' }}>
            First Attempt Lift Success Rate
          </Typography>
          <Typography variant="h6" sx={{ color: "#9E9E9E", fontSize: '12px', mt: '-4px' }}>
            { time === 1 ? 'Yesterday' : time === 0 ? 'Today' : dayjs.unix(startTime).format('DD/MM/YY') + ' - ' + dayjs.unix(endTime).format('DD/MM/YY')}
          </Typography>
          {isLoading && (
            <CircularProgress />
          )}
          {dataLift && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column'
            }}>
              <BarChart
                height={300}
                series={liftStatRates
                  .map((s) => ({ ...s, data: s.value, label: s.name, stack: 'default' }))}
                xAxis={
                  [{
                    data: dayArray,
                    scaleType: 'band'
                  }]
                }
                skipAnimation
              />
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px'
              }}>
                <Typography variant="caption">Total Number of Lift Trips:</Typography>
                <Typography variant="caption" fontWeight={600}>{ totalTrips }</Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px'
              }}>
                <Typography variant="caption">Number of Lift Trips without Retries: </Typography>
                <Typography variant="caption" fontWeight={600}>{ totalNoRetries }</Typography>
              </Box>
            </Box>
          )}
          {!isLoading && !dataLift && (
            <Box sx={{ width: '100%', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" sx={{ fontSize: '12px' }}>
                No Data to Display
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Grid>
  )
}