export const defaultHeaderStyle = {
  fontSize: "14px",
  color: "grey",
  padding: "15px 50px", 
  fontWeight: "bold",
  backgroundColor: "#F5F9FF",
  whiteSpace: "nowrap"
};

export const defaultTableCellStyle = {
  fontSize:"12px",
  color:"black",
  padding:"15px",
  // backgroundColor:"white"
}