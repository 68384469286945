import { RobotStatus2 } from "../../routes/robots/components/v2/_type"
import { getProfile, useRequest } from "../../utils/api"
import { RequestConfigType } from "../../utils/api.type"
import { RobotResponseType } from "./index.type"

const GET_ROBOT = '/v1/rms/robot/'
interface RobotListProps extends RequestConfigType {
  pageSize?: number,
  page?: number,
  organisation_name?: string
}
export const useRobots = (obj = {} as RobotListProps) => {
  return useRequest<RobotResponseType>(GET_ROBOT, {
    ...obj,
    pageSize: obj.pageSize || 20,
    limit: obj.pageSize || 20,
    page: obj.page || 1,
  }, {
    enabled: obj.enabled
  })
}

const GET_ROBOT_STATUS = '/v1/rms/robot_status/'
type RobotStatusProps = RequestConfigType & {
  organisation_id?: string
}
export const useRobotStatus = (obj = {} as RobotStatusProps) => {
  return useRequest<RobotStatus2[]>(GET_ROBOT_STATUS, {
    organisation: obj.organisation_id || (getProfile()).organisation
  }, {
    enabled: obj.enabled
  })
}
