import React, { useState, useEffect, useRef } from "react";
// import { Box, Button, Grid, Typography, Stack, TextField, Menu, MenuItem, IconButton, Divider } from "@mui/material";
import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Button, Card, InputAdornment, SelectChangeEvent } from "@mui/material";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import OtherDetails from "./OtherDetails";
import ToggleTab from "./ToggleTab";
import delivery from '../../../assets/delivery.png';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from "react-redux";
import { updateField, addRecipientLocation, updateRecipientLocation, removeRecipientLocation, setRecipientLocationInForm, updateRecipientIndex } from "../../../store/job/formSlice";
import { setSearchQuery } from "../../../store/job/searchSlice";
import { FormDataProps } from "../../../store/types";
import { VIEW } from "../../../utils/breakpoint";
// import { MapDetails3 } from "../../../store/types";

interface Map2 {
    map_name: string;
    map: string;
    building: string;
    robot_map_name: string;
}

export interface MapDetails3 {
    location_name: string;
    map: Map2[];
    pose: Pose2;
    store_manager_id: string;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
}

interface RobotPose {
    latitude: number;
    longitude: number;
    orientation: number;
}
interface RobotStatus2 {
    battery: number;
    can_assign_job: boolean;
    is_online: boolean;
    map: string;
    organisation: string;
    pose: RobotPose;
    robot_id: string;
    robot_state: string;
    robot_type: string;
    timestamp: number;  
    robot_name: string;
    robot_serial_number: string;
}


interface GeneralRobotInfo{
    robot_name: string;
    robot_mac: string;
    robot_serial_number: string;
    robot_type: string;
    organisation: string;
}

interface RobotInfo {
    robotStatus?: RobotStatus2;
    jobs?: JobData;
    generalInfo: GeneralRobotInfo;
}

interface RobotsData {
    [robot_id: string]: RobotInfo;
}

interface JobData {
    [order_number: string]: JobDetails2;
}

interface JobHistory {
    [order_number: string]: JobDetails2[];
}

const defaultJob: JobDetails2 = {
    compartment_pin: "",
    id: 0,
    job_type: "",
    job_id: 0,
    organisation_id: "",
    load_compartment: 0,
    recipient_id: "",
    recipient_location: "",
    recipient_name: "",
    robot_id: "",
    sender_id: "",
    sender_location: "",
    sender_name: "",
    timestamp: 0,
    order_number: "",
    job_status: ""
}

interface DeliveryBoardProps {
    timeFocused: boolean;
    handleTimeFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
    handleTimeBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
    handleIsOpen: () => void;
    handleStartDeliveryFocus: () => void;
    handleStartDeliveryBlur: (index: number) => void;
    isPickUpFocused: boolean;
    isDropOffFocused: boolean;
    resetFocus: () => void;
    handleLocationSelect: (location: string, deliveryType: string) => void;
    handleRecipientLocationChange: (location: string, index: number) => void;

    fieldCompleted: boolean;
    isSubmitted: boolean;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    form: FormDataProps;
    robots: RobotsData;
    handleFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<string>) => void;
    // maps: MapDetails2[];
    maps: MapDetails3[];
    handleClearTextField: (field: string) => void;
}

interface Pose2 {
    latitude: number;
    longitude: number;
    theta: number;
    z: number;
}

interface MapDetails2 {
    building: string;
    location_name: string;
    map: string;
    map_name: string;
    pose: Pose2;
    store_manager_id: string;
}

const DeliveryBoard: React.FC<DeliveryBoardProps> = ({ 
    timeFocused, 
    handleTimeFocus, 
    handleTimeBlur, 
    handleIsOpen, 
    handleStartDeliveryFocus, 
    handleStartDeliveryBlur, 
    isDropOffFocused, 
    isPickUpFocused, 
    resetFocus, 
    handleLocationSelect, 
    fieldCompleted, 
    isSubmitted, 
    handleSubmit, 
    form,
    handleFieldChange,
    maps,
    handleClearTextField,
    robots,
    handleRecipientLocationChange,
    handleSelectChange,
    }) => {

    const dispatch = useDispatch();
    const formState = useSelector((state: any) => state.form.form);
    const recipient_locations = useSelector((state: any) => state.form.recipient_locations);
    const isSubmittedState = useSelector((state: any) => state.form.isSubmitted);
    const isLoading = useSelector((state: any) => state.form.isLoading);
    const error = useSelector((state: any) => state.form.error);


    const [floorTab, setFloorTab] = useState({ pickUp: 0, dropOff: 0 });

    const handleTabChange = (newValue: number, deliveryType: 'pickUp' | 'dropOff') => {
        console.log(newValue, deliveryType);
        const {name, value} = {name: deliveryType, value: newValue};
        setFloorTab(prev => ({
            ...prev,
            [name]: value
        }));
        console.log(floorTab);
    };

    // const [recipientLocations, setRecipientLocations] = useState<string[]>([form.recipient_location]);

    const handleAddRecipientLocation = () => {
        // if (recipientLocations.length < 3) {
        //     setRecipientLocations([...recipientLocations, ""]);
        // }
        dispatch(addRecipientLocation());
    };

    // const handleRecipientLocationChange = (value: string, index: number) => {
    //     dispatch(updateRecipientLocation({ index, value }));  
    //     // dispatch(setSearchQuery(value));
    //     // const updatedLocations = recipientLocations.map((location, i) => {
    //     //     if (i === index) return value;
    //     //     return location;
    //     // });
    //     // setRecipientLocations(updatedLocations);
    // };

    const handleRemoveRecipientLocation = (index: number) => {
        // if (index > 0) { // Prevent removal of the first location
        //     const updatedLocations = recipientLocations.filter((_, i) => i !== index);
        //     setRecipientLocations(updatedLocations);
        // }
        dispatch(removeRecipientLocation(index));
    };
    
    return ( 
            <form onSubmit={handleSubmit}>
                <Stack
                    sx={(theme) => ({
                        [theme.breakpoints.up(VIEW.PHONE)]: {
                          position: 'fixed',
                          inset: 0,
                          height: '100vh',
                          width: '100vw'
                        },
                        [theme.breakpoints.up(VIEW.TABLET)]: {
                          position: "absolute",
                          left: "27.3px",
                          width: "420px",
                          bgcolor: "white",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: "10px",
                          maxHeight: "468px",
                          height: "55vh",
                          top: "auto",
                          bottom: "50px"
                        },
                        [theme.breakpoints.up(VIEW.DESKTOP)]:{
                          height: "468px",
                          top: "200px",
                          bottom: "100px",
                          left: "40px",
                        },
                        bgcolor: "white",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        overflowY: "auto",
                        overflowX: "hidden",
                        zIndex: 1100,
                    })}>
                        {isSubmitted ? (
                            <>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", m:1, mr:2 }}>
                                    <IconButton onClick={handleIsOpen}>
                                        <CloseIcon sx={{ color: "#000", fontSize:"20px" }} />
                                    </IconButton>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", m:1, mt:3 }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", m:1 }}>
                                        <img src={delivery} alt="delivery" style={{ width: "180px", height: "180px" }} />
                                    </Box>
                                    <Typography variant="h6" sx={{ color: "#12B76A", fontWeight:"600", fontSize: "18px" }}>
                                        Delivery Scheduled Successfully!
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: "#000", fontWeight:"500", fontSize: "16px", mt:1, textAlign:"center", m:3 }}>
                                        Thank you for scheduling your delivery with us! We will let you know once your order is being processed.
                                    </Typography>
                                </Box>
                            </>
                        )
                        : (
                            <Box>
                                <Box sx={{ display: "flex", boxShadow: isPickUpFocused || isDropOffFocused ? "0px 2px 2px rgba(0, 0, 0, 0.25)": "none"}}>
                                    <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m:1 }}>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", m:1 }}>
                                            <Typography variant="h6" sx={{ color: "#000", ml:1, fontWeight:"600" }}>
                                                Start Delivering
                                            </Typography>
                                            <IconButton onClick={handleIsOpen}>
                                                <CloseIcon sx={{ color: "#000", fontSize:"20px" }} />
                                            </IconButton>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", m:1, mt:1 }}>
                                            <RadioButtonUncheckedIcon sx={{ color: "#378FFE", mt:2, ml:1, fontSize:"20px" }} />
                                            <TextField 
                                                name="sender_location"
                                                id="sender_location" 
                                                placeholder="Choose Starting Point or Select from Map" 
                                                variant="outlined"
                                                onFocus={handleStartDeliveryFocus}
                                                value={form.sender_location}
                                                onChange={handleFieldChange}
                                                sx={{ 
                                                    width: "100%", 
                                                    ml: 2, 
                                                    mr: 2,
                                                    "& .MuiOutlinedInput-root": {
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "#2C8DFF", 
                                                        },
                                                    },
                                                }} 
                                                // InputProps={{
                                                //     endAdornment: (
                                                //         <InputAdornment position="end">
                                                //             {form.pickUp && (
                                                //                 <IconButton onClick={() => handleClearTextField("pickUp")} edge="end">
                                                //                     <CloseIcon />
                                                //                 </IconButton>
                                                //             )}
                                                //         </InputAdornment>
                                                //     ),
                                                // }}
                                            />
                                        </Box>
                                        {form.pickupOption === "call_nearest" ?
                                            null: 
                                        <Box sx={{ display: "flex", flexDirection: "row", m:1, mt:1 }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%',  }}>
                                            {recipient_locations.map((location: string, index: number) => (
                                                <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <FmdGoodIcon sx={{ color: "#FF2525", mt:index===0? 0:2, ml:1, fontSize:"20px" }} />
                                                    <TextField
                                                        name= "recipient_location"
                                                        // name = { index = }
                                                        placeholder="Select Destination" 
                                                        onFocus = {() => handleStartDeliveryBlur(index)}
                                                        // value={form.recipient_location}
                                                        // onChange={handleFieldChange}
                                                        value={location}
                                                        onChange={(e) => handleRecipientLocationChange(e.target.value, index)}
                                                        label="Recipient Location"
                                                       
                                                        fullWidth
                                                        sx={{ 
                                                            width: "100%", 
                                                            mt: index > 0 ? 2 : 0,
                                                            ml: 2, 
                                                            mr: 2,
                                                            "& .MuiOutlinedInput-root": {
                                                                "&.Mui-focused fieldset": {
                                                                    borderColor: "#2C8DFF",
                                                                },
                                                            },
                                                        }} 
                                                        required={index === 0} // First field is required
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {index > 0 && ( // Allow removal of fields except the first
                                                                        <IconButton onClick={() => handleRemoveRecipientLocation(index)}>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    {index === recipient_locations.length - 1 && recipient_locations.length > 0 && recipient_locations.length < 3 && (
                                                        <Button onClick={handleAddRecipientLocation} variant="outlined" sx={{ height: '55px', mt: index === 0 ? 0 : 2, mr:2,  borderColor:"#c4c4c4" }}>
                                                            <AddIcon />
                                                        </Button>
                                                    )}
                                                    <Stack 
                                                        direction="row" 
                                                        spacing={2} 
                                                        sx={{                         
                                                            position: "absolute",
                                                            top: index === 1 ? "192px" : index === 2 ? "267px" : "120px",
                                                            left: "25px" 
                                                        }}>
                                                        <MoreVertIcon sx={{ color: "#BCBCBC", mt:2.5, ml:1, fontSize:"20px" }} />
                                                    </Stack>
                                                </Box>
                                            ))}
                                            
                                            </Box>
                    
                                        </Box>
                                        }
                                        
                                    </Box>
                                </Box>
                                {!isPickUpFocused && !isDropOffFocused? (
                                    <OtherDetails 
                                        timeFocused={timeFocused}
                                        handleTimeFocus={handleTimeFocus}
                                        handleTimeBlur={handleTimeBlur} 
                                        fieldCompleted={fieldCompleted}
                                        form={form}
                                        handleFieldChange={handleFieldChange}
                                        handleSelectChange={handleSelectChange}
                                        robots={robots}
                                    /> 
                                ): isPickUpFocused ? (
                                    <ToggleTab handleTabChange={handleTabChange} tabValue={floorTab.pickUp} mapDetails={maps} deliveryType="pickUp" handleLocationSelect={(location) => handleLocationSelect(location, 'Pick Up')} resetFocus={resetFocus} />
                                ) : (
                                    <ToggleTab handleTabChange={handleTabChange} tabValue={floorTab.dropOff} mapDetails={maps} deliveryType="dropOff" handleLocationSelect={(location) => handleLocationSelect(location, 'Drop Off')} resetFocus={resetFocus} />
                                )}
                    </Box>
                )}
                </Stack>
            </form>
    );
}
 
export default DeliveryBoard;