import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { JobEnums } from "../../../utils/enums/JobEnums";
import { COLOR } from "../../../utils/colors";
import { TimeNumberProps } from "../../jobs/Components/_type";
import { JobType } from "../../../hooks/job/index.type";

export default function DPChart({
  startTime,
  endTime,
  data,
  isLoading
}: TimeNumberProps & { data: JobType[], isLoading: boolean }) {
  const time = dayjs.unix(endTime).diff(dayjs.unix(startTime), 'days')
  
  const dayArray = Array.from(Array(time + 1).keys()).map((i) => dayjs.unix(startTime).add(i, 'days').format('DD/MM/YY'))
  const defaultFailedDelivery = [
    {
      name: 'Failed',
      value: Array.from(Array(time + 1)).map((_) => 0),
      color: COLOR.graph.red
    }, {
      name: 'Cancelled',
      value: Array.from(Array(time + 1)).map((_) => 0),
      color: COLOR.graph.orange
    }, {
      name: 'Completed',
      value: Array.from(Array(time + 1)).map((_) => 0),
      color: COLOR.graph.green
    }
  ]

  const listTask = data.filter(i => i.from_location.includes('DP'))
  const [mockFailedData, setMockFailedData] = useState<typeof defaultFailedDelivery>([])

  const mapDays = (dataArray: typeof defaultFailedDelivery, dateformat: string, failedDataKey: number) => {
    const findIndex = dayArray.findIndex((item) => item === dateformat)

    if (findIndex < 0) {
      return
    }

    dataArray[failedDataKey].value[findIndex]++
  }

  const mapValue = useCallback(() => {
    if (!data) {
      return
    }

    const tmpData = ([...defaultFailedDelivery].map((i) => ({
      ...i,
      value: Array.from(Array(time + 1)).map((_) => 0),
    })))

    const counterFailed = listTask.filter((i) => [JobEnums.RETURN_FAILED, JobEnums.DELIVERY_FAILED, JobEnums.PICKED_UP_FAILED].includes(i.status))
      .map((item) => {
        const date = dayjs.unix(item.created_time).format('DD/MM/YY')
        mapDays(tmpData, date, 0)
      })

    const counterCancelled = listTask.filter((i) => [JobEnums.CANCELLED, ].includes(i.status))
      .map((item) => {
        const date = dayjs.unix(item.created_time).format('DD/MM/YY')
        mapDays(tmpData, date, 1)
      })

    const counterCompleted = listTask.filter((i) => [JobEnums.ITEM_DELIVERED].includes(i.status))
      .map((item) => {
        const date = dayjs.unix(item.created_time).format('DD/MM/YY')
        mapDays(tmpData, date, 2)
      })

      setMockFailedData(tmpData)
  }, [data, startTime, endTime])

  useEffect(() => {
    mapValue()
  }, [data])

  return (
    <Grid item xs={12} md={6}>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          width: "100%",
          flex: "auto",
          border: `1px solid ${COLOR.gray[400]}`
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            my: '12px',
            ml: '14px',
            mr: '24px',
            gap: '6px'
          }}
        >
          <Typography className='font-poppins' variant="h6" sx={{ color: COLOR.black, fontSize: '16px' }}>
            DP Delivery Orders
          </Typography>
          <Typography variant="h6" sx={{ color: "#9E9E9E", fontSize: '12px', mt: '-4px' }}>
            { time === 1 ? 'Yesterday' : time === 0 ? 'Today' : dayjs.unix(startTime).format('DD/MM/YY') + ' - ' + dayjs.unix(endTime).format('DD/MM/YY')}
          </Typography>
          {isLoading && (
            <CircularProgress />
          )}
          {mockFailedData && mockFailedData.length > 0 && (
            <BarChart
              height={300}
              series={mockFailedData
                .map((s) => ({ ...s, data: s.value, label: s.name, stack: 'default' }))}
              xAxis={
                [{
                  data: dayArray,
                  scaleType: 'band'
                }]
              }
              skipAnimation
            />
          )}
        </Box>
      </Paper>
    </Grid>
  )
}