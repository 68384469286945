import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, InputAdornment, Skeleton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import swiftbot from '../../../assets/swiftbot.png'; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fuse from 'fuse.js';
import JobDetails from "./JobDetails";
import { getStatusColor } from "../../../utils/ColourStatus";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from 'react-redux';
import { VIEW } from '../../../utils/breakpoint';

interface JobBoardProps {
    handleJobBoardClose: () => void;
    handleSelectedJob: (job: JobDetails2) => void;
    selectedJob: JobDetails2;
    imageHeight: number;
    activeStep: number;
    handleJobSearchField: (event: React.ChangeEvent<HTMLInputElement>) => void;
    searchValue: string;
    listOfJobs: JobData;
    handleBackButton: () => void;
    getJobList: (status: string, page?: number) => void; // Updated to accept status
    hasMoreJobs: boolean;
    handleSetMoreJobs: (value: boolean) => void;
    getRecurringJobs: (pageNumber?: number) => void;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    job_status: string;
    order_number: string;
    recurring_type: string;
}

interface JobData {
    [order_number: string]: JobDetails2;
}

const JobBoard: React.FC<JobBoardProps> = ({ getRecurringJobs, handleSetMoreJobs, hasMoreJobs, getJobList, handleJobBoardClose, handleSelectedJob, selectedJob, imageHeight, activeStep, handleJobSearchField, searchValue, listOfJobs, handleBackButton }) => {
    const [hasMore, setHasMore] = useState(true);
    const [tabValue, setTabValue] = useState(0);

    const listJobs = Object.keys(listOfJobs).map(order_number => ({
        ...listOfJobs[order_number]
    }));

    const fuse = new Fuse(listJobs, {
        keys: ['order_number'],
        threshold: 0.3
    });

    const [isShowContent, setIsShowContent] = useState(false)

    useEffect(() => {
      setIsShowContent(true)
    }, [])

    // Get search results if searchValue is not empty
    const searchResults = searchValue ? fuse.search(searchValue).map(result => result.item) : listJobs;

    const pageNumber = useSelector((state: any) => state.jobPage.currentPageNumber);    
    const totalPageNumber = useSelector((state: any) => state.jobPage.totalPageNumber);
    
    // Fetch more jobs
    const fetchMoreJobs = async () => {
        console.log("Fetching more jobs", pageNumber, totalPageNumber, tabValue);
        if (hasMoreJobs && pageNumber + 1 <= totalPageNumber ) {
          await getJobList(tabValue === 0 ? "in_progress" : tabValue === 1 ? "completed" : tabValue === 2 ? "failed" : "", pageNumber + 1);
        }
        // else if (tabValue === 2 && hasMoreJobs && pageNumber + 1 <= totalPageNumber) {
        //   await getRecurringJobs(pageNumber + 1);
        // }
    };

    // Handle tab change and query jobs accordingly
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        getJobList(newValue === 0 ? "in_progress" : newValue === 1 ? "completed" : "failed");
        // if (newValue === 2) {
        //     getRecurringJobs();
        // }
        // else {
        //     getJobList(newValue === 0 ? "in_progress" : newValue === 1 ? "completed" : "failed");
        // }

    };

    return (
        <Stack sx={(theme) => ({
            [theme.breakpoints.up(VIEW.PHONE)]:{
              position: 'fixed',
              inset: 0,
              height: '100vh',
              width: '100vw',
              zIndex: 1100,
              marginTop: '0px !important'
            },
            [theme.breakpoints.up(VIEW.TABLET)]:{
              position: "absolute",
              top: isShowContent ? "225px" : "220px",
              left: 'auto',
              bottom: 'auto',
              right: "20px",
              width: "400px",
              height: '54vh',
              maxHeight: selectedJob.order_number !== "" ? "450px" : "345px",
              zIndex: 1005
            },
            [theme.breakpoints.up(VIEW.DESKTOP)]: {
              top: isShowContent ? "251px" : "240px",
              height: selectedJob.order_number !== "" ? "450px" : "345px",
            },
            opacity: isShowContent ? 1 : 0,
            
            bgcolor: "white",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            overflowY: "hidden",
            overflowX: "hidden",
            transition: 'all',
            transitionDuration: '300ms',
          })}>
            {selectedJob.order_number !== "" ? (
                <JobDetails job={selectedJob} handleJobBoardClose={handleJobBoardClose} imageHeight={imageHeight} activeStep={activeStep} handleBackButton={handleBackButton} />
            ) : (
                <>
                    <Box sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)", height: "150px", display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m: 1 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", m: 1 }}>
                                <Typography sx={{ color: "#000", ml: 1, fontWeight: "600", fontSize: "16px", mt: 0.2 }}>
                                    Jobs
                                </Typography>
                                <IconButton onClick={handleJobBoardClose}>
                                    <CloseIcon sx={{ color: "#000", fontSize: "20px" }} />
                                </IconButton>
                            </Box>
                            <TextField
                                id="search-job"
                                placeholder="Search Job ID?"
                                value={searchValue}
                                onChange={handleJobSearchField}
                                variant="outlined"
                                sx={{ width: "92%", m: "auto", '& .MuiInputBase-root': { height: '50px', fontSize: "14px" }, "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#2C8DFF" } } }}
                                InputProps={{ endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment> }}
                            />
                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "240px" }}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    sx={{ ml: 2, mt: 1, fontSize: "20px", '.MuiTabs-indicator': { display: 'none' }, '& .MuiTab-root': { color: '#A0A0A0', fontSize: '10px', height: '20px', minHeight: '20px', '&.Mui-selected': { color: '#087D46', backgroundColor: '#00E58E', borderRadius: '30px' } } }}>
                                    <Tab label="In Progress" />
                                    <Tab label="Completed" />
                                    {/* <Tab label="Scheduled" /> */}
                                    <Tab label="Failed" />
                                </Tabs>
                            </Box>
                        </Box>
                    </Box>
                    
                    {/* Display Infinite Scroll only when there are jobs */}
                    {searchResults.length > 0 ? (
                        <Box id="scrollableDiv" sx={(theme) => ({
                          [theme.breakpoints.down(VIEW.TABLET)]: {
                            height: '100%'
                          },
                          [theme.breakpoints.up(VIEW.TABLET)]: {
                            height: '250px'
                          },
                          overflowY: "auto" })}>
                            <InfiniteScroll
                                dataLength={listJobs.length}
                                next={fetchMoreJobs}
                                hasMore={hasMoreJobs}
                                loader={
                                    <Box sx={{ padding: 2 }}>
                                        <Skeleton variant="rectangular" height={50} sx={{ marginBottom: 1 }} />
                                        <Skeleton variant="rectangular" height={50} sx={{ marginBottom: 1 }} />
                                        <Skeleton variant="rectangular" height={50} />
                                    </Box>
                                }
                                endMessage={
                                    <Typography sx={{ textAlign: 'center', mt: 2 }}>
                                        No more Jobs
                                    </Typography>
                                }
                                scrollableTarget="scrollableDiv"
                            >
                                <List>
                                    {searchResults.map((job, index) => (
                                        <React.Fragment key={index}>
                                            <ListItem sx={{ py: 1, '&:hover': { backgroundColor: '#f4f4f4', cursor: 'pointer' }, paddingLeft: "35px" }} onClick={() => handleSelectedJob(job)}>
                                                <ListItemIcon>
                                                    <Stack sx={{ width: "60px", ml: -1, height: "60px", bgcolor: "#f5f5f5", color: "white", fontWeight: "600", borderRadius: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <img src={swiftbot} style={{ width: "40px", height: "40px" }} />
                                                    </Stack>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "right", ml: 3 }}>
                                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <Typography sx={{ color: "#000", fontSize: "16px", fontWeight: "500" }}>
                                                                {/* Job #{job.order_number.slice(-5).toUpperCase()} */}
                                                                Job #{job.order_number}
                                                            </Typography>
                                                            <Typography sx={{ color: "#727272", fontSize: "12px" }}>
                                                                Today, {new Date(job.timestamp * 1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                                            </Typography>
                                                        </Box>
                                                        <Typography sx={{ color: getStatusColor(job.job_status), fontSize: "12px" }}>
                                                            {job.job_status.replace(/_/g, " ").split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                                        </Typography>
                                                    </Box>
                                                </ListItemText>
                                            </ListItem>
                                            {index < searchResults.length - 1 && <Divider sx={{ width: "85%", margin: "auto" }} />}
                                        </React.Fragment>
                                    ))}
                                </List>
                            </InfiniteScroll>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100%', mt: 1 }}>
                            <Typography sx={{ color: "#000", fontSize: "18px", mt: 1.5 }}>
                                {tabValue === 0 ? "No Jobs in Progress" : tabValue === 1 ? "No Completed Jobs" : "No Failed Jobs"}
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </Stack>
    );
}

export default JobBoard;

