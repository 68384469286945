import React, { useState, useEffect, useRef } from "react";
// import { Box, Button, Grid, Typography, Stack, TextField, Menu, MenuItem, IconButton, Divider } from "@mui/material";
import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PlaceIcon from '@mui/icons-material/Place';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OtherDetails from "./OtherDetails";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { MapDetails3 } from "../../../store/types";
import { useDispatch } from "react-redux";
import { setCurrentFloor } from "../../../store/map/mapSlice";
import { VIEW } from "../../../utils/breakpoint";

interface Pose2 {
    latitude: number;
    longitude: number;
    theta: number;
    z: number;
}

interface MapDetails2 {
    building: string;
    location_name: string;
    map: string;
    map_name: string;
    pose: Pose2;
    store_manager_id: string;
}

interface ToggleTabProps {
    tabValue: number;
    handleTabChange: (newValue: number, deliveryType: 'pickUp' | 'dropOff') => void; // This definition needs to match expected usage
    mapDetails: MapDetails3[];
    deliveryType: 'pickUp' | 'dropOff';
    handleLocationSelect: (location: string) => void;
    resetFocus: () => void;
}

const ToggleTab: React.FC<ToggleTabProps> = ({ tabValue, handleTabChange, mapDetails, deliveryType, handleLocationSelect }) => {
    const dispatch = useDispatch();
    const uniqueLevels = Array.from(new Set(mapDetails.map(detail => detail.map[0].map_level)));
    
    useEffect(() => {
        console.log(uniqueLevels);
        // Change Map Level based selected Tab (Except choose All Floor)
        const selectedLevel = tabValue - 1;
        if (selectedLevel < 0 || selectedLevel >= uniqueLevels.length) {
          return
        }
        // Find Map with specific name
        const findMap = mapDetails.find(detail => detail.map[0].map_level === uniqueLevels[selectedLevel])?.map[0]
        if (!findMap || !findMap.map_level) {
          return
        }
        // Set the Current Floor
        dispatch(setCurrentFloor(findMap.map_level.toString()))
    }
    , [uniqueLevels]);
    const renderLocationDetails = (locations: MapDetails3[], color: string) => 
        (
        <List>
            {locations.map((location, index) => (
                <React.Fragment key={index}>
                    <ListItem button onClick={() => { handleLocationSelect(location.location_name) }} sx={{ '&:hover': { backgroundColor: '#f4f4f4' }}}>
                        <ListItemIcon>
                            <PlaceIcon sx={{ color: color }} />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="subtitle1" sx={{ fontWeight: 550, color:"black", fontSize:"14px" }}>{location.map[0].building}</Typography>
                            <Typography variant="subtitle2" sx={{ color: "#000", fontSize:"14px" }}>{location.location_name}</Typography>
                        </ListItemText>
                    </ListItem>
                    {index < locations.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </List>
    );
    return ( 
        <Box sx={(theme) => ({
            [theme.breakpoints.up(VIEW.PHONE)]: {
              height:"calc(100vh - 212px)",
            },
            [theme.breakpoints.up(VIEW.TABLET)]: {
              height:"calc(406px - 212px)",
            },
            [theme.breakpoints.up(VIEW.DESKTOP)]: {
              height:"calc(468px - 212px)",
            },
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow:"auto"
          })}>
            <Tabs 
                value={tabValue} 
                onChange={(event, newValue) => handleTabChange(newValue, deliveryType)}
                sx={{
                    ml:2,
                    mt:2,
                    fontSize: "8px",
                    '.MuiTabs-indicator': {
                        display: 'none',  
                    },
                    '& .MuiTab-root': {
                        color: '#A0A0A0', 
                        fontSize: '12px',
                        height: '20px', 
                        minHeight: '20px',
                        '&.Mui-selected': {
                            color: '#087D46', 
                            backgroundColor: '#00E58E',
                            borderRadius: '30px',
                        }
                    }
            }}>
                <Tab label="All Floors" />
                {uniqueLevels.map((level, index) => (
                    <Tab key={index} label={`Level ${level}`} />
                ))}
            </Tabs>
            <Box sx={{mt:-2.5}}>
                {tabValue === 0 && renderLocationDetails(mapDetails, deliveryType === "pickUp" ? "#378FFE" : "#FF0B0B")}
                {uniqueLevels.map((level, index) => (
                    <Box key={index}>
                        {tabValue === index + 1 && renderLocationDetails(mapDetails.filter(detail => detail.map[0].map_level === level), deliveryType === "pickUp" ? "#378FFE" : "#FF0B0B")}
                    </Box>
                ))}
            </Box>
    </Box>
    );
}
 
export default ToggleTab;