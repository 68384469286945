import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { Column, RobotInfo } from "./_type";
import { defaultHeaderStyle, defaultTableCellStyle } from "./_style";
import { useRobots } from "../../../../hooks/robot";
import { useEffect, useState } from "react";
import Fuse from "fuse.js";
import { RobotWithOrganisationNameType } from "../../../../hooks/robot/index.type";
import swiftbot from "../../../../assets/swiftbot.png";
import { dummyRobotTableData } from "./_const";
import { useNavigate } from "react-router-dom";

const columns: Column[] = [
  { id: "preview", label: "Preview" },
  { id: "robot_name", label: "Robot Name" },
  { id: "robot_type", label: "Robot Type" },
  { id: "robot_serial_number", label: "Serial No." },
  { id: "robot_mac", label: "Mac Address" },
  { id: "organisation_name", label: "Organisation" },

  // hardcoded for now
  { id: "site", label: "Site" },
  { id: "site_address", label: "Site Address" },
  { id: "customer_name", label: "Customer" },
  { id: "customer_contact", label: "Customer Contact" },
];

// Extract the `id` values as a union type
type ColumnId = typeof columns[number]["id"];
type ColumnData = Partial<{ [key in ColumnId]: string }>

const fuseOptions = {
  keys: ["robot_name", "robot_mac", "robot_serial_number", "robot_type", "organisation_name"],
  includeScore: true
}

export default function RobotTablev2({
  search
}: {
  search?: string
}) {
  const renderHeader = (column: Column) => {
    return {
      ...defaultHeaderStyle,
      padding: column.padding || defaultHeaderStyle.padding
    };
  };
  const navigate = useNavigate();

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { data: dataRobot, refetch: refetchRobots } = useRobots({
    organisation_name: 'Autove',
    pageSize: rowsPerPage,
    page
  })
  const robots = dataRobot?.data || []
  const dataCount = dataRobot?.count
  const robotDatas: RobotWithOrganisationNameType[] = robots.map((item) => ({
    ...item,
    organisation_name: 'Autove',
  }))
  const fuse = new Fuse(robotDatas, fuseOptions)
  const filteredRobots = search ? fuse.search(search)
    .filter(({ score }) => score && score <= 0.25)
    .map(({ item }) => item)
    : robotDatas;

  const rowsToShow = filteredRobots.map((data) => ({
    preview: (<img src={swiftbot} style={{ width: "40px", height: "60px", objectFit: 'scale-down' }} />),
    ...dummyRobotTableData,
    ...data
  } as unknown as ColumnData[]))

  const itemCount = (search ? filteredRobots.length : dataCount) || 0

  const renderRow = (info: any) => {
    return (
      <TableRow
        key={info.robot_serial_number}
        sx={{ cursor: 'pointer', backgroundColor: "white", '&:hover': { backgroundColor: '#f5f5f5' } }}
        onClick={() => handleRowClick(info.robot_mac)}
      >
        {columns.map(column => (
          <TableCell key={column.id} align="center" sx={defaultTableCellStyle}>
            {info[column.id as keyof ColumnId]}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const handleRowClick = (robotId: string) => {
    // console.log("Row clicked, robot ID:", robotId);
    navigate(`/robot/${robotId}`);
  };

  const handleChangePage = (num: number) => setPage(num)
  const handleChangeRowsPerPage = (num: number) => {
    setRowsPerPage(num)
    setPage(1)
  }

  useEffect(() => {
    refetchRobots()
  }, [page, rowsPerPage])
  return (
    <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', maxHeight: "50vh", borderRadius: "10px", border: "1px solid #E0E0E0", m: '20px auto' }}>
      <TableContainer sx={{ backgroundColor: "#F5F5F5", alignItems: "center", overflowX: "auto" }}>
        <Table stickyHeader>
          <TableHead sx={{ width: "100%", margin: "auto" }}>
            <TableRow sx={{ width: "100%", margin: "auto" }}>
              {columns.map(column => (
                <TableCell key={column.id} align="center" sx={renderHeader(column)}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsToShow.map(info => renderRow(info))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={itemCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(e, num) => handleChangePage(num)}
        onRowsPerPageChange={(e) => handleChangeRowsPerPage(parseInt(e.target.value || '10'))}
        sx={{ flexShrink: 0, boxShadow: "none" }} // Prevent pagination from shrinking
      />
    </Paper>
  )
}