import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContentText, Typography, Box, IconButton, DialogActions, DialogContent } from '@mui/material';
import swiftbot from '../../../assets/swiftbot.png';
import { formatDate } from '../../../utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { setModalClose, setFireAlarmOff, setFireAlarmOn, triggerFire } from '../../../store/fire/fireTriggerSlice';
import { setNotificationOn, setNotificationOff, setNotificationMessage, setNotificationTitle, setNotificationType, setNotificationColorProps } from '../../../store/notification/notificationSlice';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationType, RobotData3, RobotInfo3, RobotStatus2 } from '../../../store/types';
import { getRobotStatus } from '../../../lib/api/robot';
import { useWebSocket } from '../../../components/useWebSocket';
import { getProfile } from '../../../utils/api';
import { VIEW } from '../../../utils/breakpoint';
import { WidthFull } from '@mui/icons-material';

interface NotificationProps {
    open?: boolean;
    message?: string;
    onClose?: (id: number) => void;
    onDismissAlarm?: () => void;
}

interface colorProps {
    background: string;
    border: string;
}

const Notification: React.FC<NotificationProps> = ({ open, message, onClose, onDismissAlarm }) => {
    const notificationType = useSelector((state: any) => state.notification.notificationType);
    const notificationMessage = useSelector((state: any) => state.notification.notificationMessage);
    const notificationTitle = useSelector((state: any) => state.notification.notificationTitle);
    const isNotificationOn = useSelector((state: any) => state.notification.isNotificationOn);
    const currentNotification: NotificationType = useSelector((state: any) => state.notification.currentNotification);
    // const isNotificationOn = useSelector((state: any) => state.notification.isNotificationOn);
    const notificationQueue = useSelector((state: any) => state.notification.notificationsQueue);
    const isEstop = currentNotification && currentNotification.notification_details.title.toLowerCase().includes("e-stop");

    const handleRobotStatus = (data: RobotStatus2) => {
      if (!currentNotification || !isEstop) {
        return
      }
      const robotId = data.robot_id;
      const robotIdCurrentNotification = JSON.parse((currentNotification as unknown as { meta: string}).meta).robot_id;

      if (data.robot_state !== 'estop' && (robotId && robotIdCurrentNotification)) {
        dismissNotification()
      }
    }

    const robotStatus = `${process.env.REACT_APP_WEBSOCKET_URL}/robot/${getProfile()?.organisation}/`;
    useWebSocket(robotStatus, handleRobotStatus);

    const dispatch = useDispatch();
    const successColor : colorProps = {
        background: "rgba(0, 255, 0, 0.2)",
        border: "10px solid #0BFA4E"
    }

    const criticalColor : colorProps = {
        background: "rgba(255, 0, 0, 0.2)",
        border: "10px solid #FF0000"
    }
    const [colorProps, setColorProps] = React.useState<colorProps>(successColor);

    useEffect(() => {
        if (notificationType === "fire" || notificationType==="Failed" || notificationType==="Robot Update" || isEstop) {
            setColorProps(criticalColor);
        }
        else {
            setColorProps(successColor);
        }

    }, [isNotificationOn]);

    const handleOnClose = () => {
        onDismissAlarm?.()
    }

    useEffect(() => {
        console.log("currentNotification", currentNotification);
        console.log("notificationQueue", notificationQueue);
    }, [currentNotification, notificationQueue]);

    
    const dismissNotification = () => {
      if (!currentNotification) {
        return
      }

      onClose?.(currentNotification.id)
    }

    if (!currentNotification) {
      console.log("No notification", currentNotification);
    }

    return currentNotification ? (
        <div>
            <style>
                {`
                @keyframes blinkBackdrop {
                    0%, 100% {
                        background-color: ${colorProps.background}; // Red semi-transparent
                    }
                    50% {
                        background-color: rgba(255, 0, 0, 0); // Fully transparent
                    }
                }
                .blink-backdrop {
                    animation: blinkBackdrop 2s linear infinite;
                }
                `}
            </style>
            <Dialog
                open={isNotificationOn}
                maxWidth={false}
                BackdropProps={{ className: 'blink-backdrop' }}
                sx={(theme) => ({
                    '& .MuiDialog-paper': {
                      [theme.breakpoints.up(VIEW.PHONE)]: {
                        left: '5px',
                        right: '5px',
                        mx: 'auto',
                        width: '90vw',
                        maxWidth: 'none'
                      },
                      [theme.breakpoints.up(VIEW.TABLET)]: {
                        width: '600px',
                      },
                      [theme.breakpoints.up(VIEW.DESKTOP)]: {
                        left: '50%',  // Center horizontally
                        right: '50%',
                        transform: 'translate(-50%, -10%)',
                        height: '250px',
                        width: '600px',
                      },
                        bgcolor: 'transparent',
                        boxShadow: 'none',
                        position: 'absolute',  // Use absolute positioning
                        top: '10%',  // Adjust this value to control the vertical position
                    }
                })}
            >
                <Box style={{
                    border: `${colorProps.border}`,
                    borderRadius: "15px",
                    backgroundColor: "#ffffff",
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        margin: 'auto',
                        bgcolor: "#fff",
                        p: 2,
                    }}>
                        <IconButton
                            // onClick={() => { dispatch(setNotificationOff()) }}
                            onClick={() => dismissNotification()}
                            // if fire alarm prompt user to confirm before disabling
                            // onClick={() => { notificationType === "fire" ? dispatch(setFireAlarmOff()) : dispatch(setNotificationOff()) }}
                            sx={{
                                position: 'absolute',
                                right: 20,
                                top: 20,
                                color: "#727272"
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box sx={(theme) => ({
                          [theme.breakpoints.down(VIEW.TABLET)]: {
                            flex: 'auto',
                            width: '100%'
                          },
                          [theme.breakpoints.up(VIEW.TABLET)]: {
                            ml:3,
                          },
                          mr:2,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          
                          })}>
                            <Box sx={(theme) => ({
                              [theme.breakpoints.up(VIEW.PHONE)]: {
                                height: "80px",
                                display: 'none'
                              },
                              [theme.breakpoints.up(VIEW.TABLET)]: {
                                height: "160px",
                                display: 'inline-block',
                                maxWidth: '200px',
                                flex: 'none'
                              },
                            })}>
                                <img src={swiftbot} alt="swiftbot" style={{ height: "100%"}} />
                            </Box>
                            <DialogContent sx={(theme) => ({
                              [theme.breakpoints.up(VIEW.TABLET)]: {
                                ml:3,
                                mb:2
                              },
                                textAlign: "left",
                                fontSize: "18px",
                                fontWeight: "bold",
                                color: "#000",
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "left",
                                flexDirection: "column",
                                padding: 0
                            })}>
                                {/* Order has been delivered */}
                                <Typography fontSize={'18px'} fontWeight={700}>
                                  {currentNotification?.notification_details.title}
                                </Typography>
                                <Typography variant="h5" component="div" sx={{
                                    color: "#727272",
                                    fontSize: "16px",
                                    textAlign: "left",
                                    mt: 2
                                }}>
                                    {/* Please attend to the robot and receive the item at the TR+43 */}
                                    {currentNotification?.notification_details.description}
                                </Typography>
                            { notificationType === "fire" &&    
                                <Button variant="contained" sx={{
                                    // position: 'absolute',
                                    // bottom: 70,
                                    // left: 185,
                                    mt: '12px',
                                    color: "#fff",
                                    backgroundColor: "#eb4034",
                                    '&:hover': {
                                        backgroundColor: "#b82218"
                                    }
                                }}
                                    onClick={handleOnClose}
                                >
                                    Disable Fire Alarm
                                </Button>
                            }
                            </DialogContent>
                        </Box>
                        <Box sx={{ }}>
                            
                        <Typography variant="h6" component="div" sx={{
                            flexGrow: 1,
                            color: "#727272",
                            fontSize: "16px",
                            textAlign: "right",
                            mt: 1
                        }}>
                            {/* {notificationType === "fire" ? formatDate(Date.now()/1000) : formatDate(currentNotification?.notification_details.created_timestamp)} */}
                            { formatDate(currentNotification?.notification_details.created_timestamp)}
                            {/* {formatDate(Date.now()/1000)} */}
                        </Typography>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </div>
    ) : (<></>);
}

export default Notification;
