import { Add } from "@mui/icons-material";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid";
import { useState } from "react";
import RobotTablev2 from "../components/v2/RobotTable";

export default function Robotsv2Page() {
  const [searchValue, setSearchValue] = useState<string>("");

  return (
    <Box sx={{ maxWidth: "95%", m: "15px auto" }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4" sx={{ mb: "15px", color: "black" }}>Robots</Typography>
        {/* <Button variant="outlined" startIcon={<Add />}>Create New</Button> */}
      </Box>
      <TextField
        id="search-robot"
        variant="outlined"
        placeholder="Search Robot"
        size="small"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        sx={{
          width: "100%",
          background: "white",
          "& .MuiOutlinedInput-root": {
            height: "40px",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <GridSearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <RobotTablev2 search={searchValue} />
    </Box>
  )
}