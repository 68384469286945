import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './routes/dashboard/dashboard';
import TopBar from './components/layouts/Topbar';
import Login from './routes/login/Login';
import ProtectedRoute from './components/ProtectedRoute'; // Adjust path as necessary
import { AuthProvider } from './context/AuthContext'; // Adjust path as necessary
import Layout from './components/layouts/MainLayout';
import JobHistory from './routes/jobs/JobHistory';
import Robots from './routes/robots/Robots';
import MainLayout from './components/layouts/MainLayout';
import RobotDetails from './routes/robotDetails/robotDetails';
import ReportHistory from './routes/reportHistory/ReportHistory';
import RoleOverview from './routes/rbac/RoleOverview';
import AddUser from './routes/rbac/users/AddUser';
import AddFeatures from './routes/rbac/features/AddFeatures';
import ScheduledJobPage from './routes/scheduledJob';
import EditUser from './routes/rbac/users/EditUser';
import EditFeatures from './routes/rbac/features/EditFeatures';

import "./global.css"
import RobotDetailPage from './routes/robotDetail';
import OrderHistory from './routes/orders/OrderHistory';
import Robotsv2Page from './routes/robots/v2/Robots-page';
import RobotDetailv2 from './routes/robotDetail/v2';

function App() {
  const [notificationCount, setNotificationCount] = React.useState<number>(0);
  const handleNotificationCount = (count: number) => {
    setNotificationCount(count);
  }
  return (
    // <Router basename="/dev">
     <Router>

      <AuthProvider> 
        <div style={{ backgroundColor: "#f5f5f5", color: "white", margin: 0, overflow: "hidden" }}>
          <Routes>
            <Route path="/" element={
              <ProtectedRoute>
                <MainLayout>
                <Dashboard onNotificationCountChange={handleNotificationCount} />
                </MainLayout>
              </ProtectedRoute>
            } />
            <Route path="/login" element={<Login />} />
            <Route path='/jobs' element={
              <ProtectedRoute>
                <MainLayout>
                <JobHistory />
                </MainLayout>
              </ProtectedRoute>
            }
            />
            <Route path='/orders' element={
              <ProtectedRoute>
                <MainLayout>
                <OrderHistory />
                </MainLayout>
              </ProtectedRoute>
            }
            />
            <Route path="/robot" element={
              <ProtectedRoute>
                <MainLayout>
                  <Robotsv2Page />
                </MainLayout>
              </ProtectedRoute>
            } />
            <Route path="/robot/:id" element={
              <ProtectedRoute>
                <MainLayout>
                  <RobotDetailv2 />
                </MainLayout>
              </ProtectedRoute>
            } />
            <Route path="/robots" element={
              <ProtectedRoute>
                <MainLayout>
                  <Robotsv2Page />
                </MainLayout>
              </ProtectedRoute>
            } />
            <Route path="/robots/:id" element={
              <ProtectedRoute>
                <MainLayout>
                  <RobotDetailv2 />
                </MainLayout>
              </ProtectedRoute>
            } />
            <Route path="/reports" element={
              <ProtectedRoute>
                <MainLayout>
                  <ReportHistory />
                </MainLayout>
              </ProtectedRoute>
            } />
             <Route path="/access-control" element={
              <ProtectedRoute>
                <MainLayout>
                  <RoleOverview />
                </MainLayout>
              </ProtectedRoute>
            } />
            <Route path="/access-control/new-user" element={
              <ProtectedRoute>
                <MainLayout>
                  <AddUser/>
                </MainLayout>
              </ProtectedRoute>
            } />
            <Route path="/access-control/new-grouping" element={
              <ProtectedRoute>
                <MainLayout>
                  {/* <AddUser/> */}
                  <AddFeatures/>
                </MainLayout>
              </ProtectedRoute>
            } />
            <Route path="/scheduled-job" element={
              <ProtectedRoute>
                <MainLayout>
                  <ScheduledJobPage/>
                </MainLayout>
              </ProtectedRoute>
            } />
            {/* {/* <Route path="/robots/:id" element={ */}
            <Route path="/access-control/edit-user/:id" element={
              <ProtectedRoute>
                <MainLayout>
                  <EditUser/>
                </MainLayout>
              </ProtectedRoute>
            } />

            <Route path="/access-control/edit-grouping/:id" element={
              <ProtectedRoute>
                <MainLayout>
                  <EditFeatures/>
                </MainLayout>
              </ProtectedRoute>
            } />
            <Route path="*" element={<Navigate to="/login" />} />
            
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}


export default App;

