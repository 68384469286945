import { useWebSocket } from "../../components/useWebSocket"
import { getProfile, useRequest } from "../../utils/api"
import { WEBSOCKET_URL } from "../../utils/const"
import { LiftStatsResponse, LiftStatusProps, LiftStatusResponse, LiftTypeProps } from "./index.type"

// For Job Page graph
const GET_LIFT_STAT = '/v1/rms/lift_job/stats/'
export const DEFAULT_LOCATION_LIMIT = 100
export const useLiftStats = (obj = {} as LiftTypeProps) => {
  return useRequest<LiftStatsResponse>(GET_LIFT_STAT, {
    ...obj
  }, { enabled: obj.enabled, method: 'POST' })
}

const ENDPOINT_LIFT_STATUS = '/v1/rms/lift_status/'
export const useLiftStatus = (obj = {} as LiftStatusProps) => {
  return useRequest<LiftStatusResponse>(ENDPOINT_LIFT_STATUS, {
    ...obj
  }, { enabled: obj.enabled })
}

const profile = getProfile()
const WS_LIFT_STATUS = `${WEBSOCKET_URL}/lift_status/${profile?.uuid}/`;
export const useLiftStatusWebsocket = ({
  onMessage,
  autoReconnect
}: {
  onMessage?: (data: any) => void,
  autoReconnect?: boolean
}) => useWebSocket(
  WS_LIFT_STATUS,
  (data) => onMessage?.(data),
  autoReconnect === undefined ? true : autoReconnect
);