import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Button, Card, InputAdornment, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import door from '../../../assets/door.png';
import elevator from '../../../assets/elevator.png';
import { generalStatus } from "../../../utils/DummyData";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { COLOR } from "../../../utils/colors";
import { getLiftStatusColor } from "../../../utils/ColourStatus";
import { useLiftStatus, useLiftStatusWebsocket } from "../../../hooks/lift";
import { LiftJobType, LiftStatusResponse } from "../../../hooks/lift/index.type";
import { getStatusLabel } from "../../../hooks/lift/_function";
import { VIEW } from "../../../utils/breakpoint";
import LiftIcon from "../../../components/RobotMap/_icons/lift";

interface GeneralDetails {
  door: StatusDetails[];
  lift: LiftStatusDetails[];
}

interface StatusDetails {
  name: string;
  status: string;
  connected: boolean;
}

interface LiftStatusDetails {
  name: string;
  tag?: string;
  current_floor?: string;
  status: string;
  connected: boolean;
  robots?: RobotLiftType[];
}

type RobotLiftType = {
  name: string,
  status: string,
  from_floor: string,
  destination_floor: string
}

interface GeneralStatusProps {
  handleStatusBoardClose?: () => void;
}

const GeneralStatus: React.FC<GeneralStatusProps> = ({ handleStatusBoardClose }) => {
  const [isShowContent, setIsShowContent] = useState(false)
  const [selectedLiftStatusFilter, setSelectedLiftFilter] = useState('');
  const [liftOfBuilding, setLiftOfBuiling] = useState<string[]>([])
  useEffect(() => {
    setIsShowContent(true)
  }, [])

  const { data: dataLift, refetch: refetchLiftStatus, isLoading: isLoadingLiftStatus } = useLiftStatus({ building: selectedLiftStatusFilter?.toLowerCase() === 'all' ? undefined : encodeURIComponent(selectedLiftStatusFilter) })
  const liftStatuses = dataLift || []

  const wsLiftStatus = useLiftStatusWebsocket({
    onMessage: () => {
      refetchLiftStatus()
    }
  })

  useEffect(() => {
    wsLiftStatus({}) 
  }, [])

  useEffect(() => {
    if (selectedLiftStatusFilter !== '' || liftOfBuilding.length > 1 || liftStatuses.length === 0) {
      return
    }
    const dataTabValues = liftStatuses.map((item) => item.building)
    const uniqueTabValues = dataTabValues.filter((value, index, self) => self.indexOf(value) === index)
    const finalTabValues = ['All', ...uniqueTabValues]
    setLiftOfBuiling(finalTabValues)
    setSelectedLiftFilter(finalTabValues[0])
  }, [dataLift])

  useEffect(() => {
    if (isLoadingLiftStatus) {
      return
    }

    refetchLiftStatus()
  }, [selectedLiftStatusFilter])

  const handleLiftFilter = (newValue: string) => {
    if (newValue === selectedLiftStatusFilter) {
      return
    }
    setSelectedLiftFilter(newValue);
  }

  const generalInfo: GeneralDetails = generalStatus;

  // const filteredJobs = tabValue === 0 ? generalInfo.door : generalInfo.lift
  // const selectedImage = tabValue === 0 ? door : elevator;

  const filterRobotJobs =(arr: LiftJobType[], map = new Map()) => {
    // NEED handle in Backend
    arr.forEach(o => map.set(o.robot_name, o));
    return [...map.values() as any] as LiftJobType[];
  };
  
  return (
    <Stack
      sx={(theme) => ({
        [theme.breakpoints.up(VIEW.PHONE)]: {
          position: 'fixed',
          inset: 0,
          height: '100vh',
          width: '100vw',
          zIndex: 1100,
          marginTop: '0px !important'
        },
        [theme.breakpoints.up(VIEW.TABLET)]: {
          // top: isShowContent ? "235px" : "220px",
          opacity: isShowContent ? 1 : 0,
          right: "20px",
          width: "400px",
          bgcolor: "white",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          height: "54vh",
          position: "absolute",
          top: isShowContent ? "225px" : "220px",
          left:'auto',
          bottom: 'auto',
          maxHeight: "450px",
          zIndex: 1005
        },
        [theme.breakpoints.up(VIEW.DESKTOP)]: {
          top: isShowContent ? "251px" : "220px",
          height: "450px",
        },
        // top: "225px",
        opacity: isShowContent ? 1 : 0,

        bgcolor: "white",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        transition: 'all',
        transitionDuration: '300ms'
      })}>
      <>
        <Box sx={{ height: "150px", display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", m: 1 }}>
              <Typography sx={{ color: "#000", ml: 1, fontWeight: "600", fontSize: "16px", mt: 0.2 }}>
                Lift Status
              </Typography>
              <IconButton onClick={handleStatusBoardClose}>
                <CloseIcon sx={{ color: "#000", fontSize: "20px" }} />
              </IconButton>
            </Box>

            {/* <Box sx={{ display: "flex", flexDirection: "column", width: "100%",}}>
                                <Tabs 
                                    value={tabValue} 
                                    onChange={(event, newValue) => handleTabChange(newValue)}
                                    sx={{
                                        ml:2,
                                        fontSize: "8px",
                                        '.MuiTabs-indicator': {
                                            display: 'none',  
                                        },
                                        '& .MuiTab-root': {
                                            color: '#A0A0A0', 
                                            fontSize: '12px',
                                            height: '20px', 
                                            minHeight: '20px',
                                            '&.Mui-selected': {
                                                color: '#087D46', 
                                                backgroundColor: '#00E58E',
                                                borderRadius: '30px',
                                            }
                                        }
                                }}>
                                    <Tab label="Door" />
                                    <Tab label="Lift" />
                                </Tabs>
                            </Box> */}
          </Box>
        </Box>
        <Box sx={{ mt: -12, display: 'flex', flexDirection: 'column' }}>
          <Tabs
            value={selectedLiftStatusFilter}
            onChange={(_, newValue) => handleLiftFilter(newValue)}
            sx={{
              ml: 2,
              fontSize: "8px",
              '.MuiTabs-indicator': {
                display: 'none',
                height: '0px !important',
              },
              '& .MuiTab-root': {
                color: '#A0A0A0',
                fontSize: '12px',
                height: '20px !important',
                minHeight: '20px',
                '&.Mui-selected': {
                  color: '#087D46',
                  backgroundColor: '#00E58E',
                  borderRadius: '30px',
                },
              },
            }}>
            {liftOfBuilding.map((level, index) => (
              <Tab key={index} value={level} label={level} />
            ))}
          </Tabs>
          <List sx={{ height: '90%', maxHeight: '47.55vh', overflowY: "auto", overflowX: "hidden", mt: '-20px' }}>
            {liftStatuses.map((lift, index) => (
              <>
                <ListItem key={index} sx={{ py: 1, paddingLeft: "35px" }}>

                  <Stack sx={{ flex: 'none', width: "60px", height: "60px", bgcolor: "#f5f5f5", color: "white", fontWeight: "600", borderRadius: "30px", display: "flex", justifyContent: "center", alignItems: "center", mr: 3 }}>
                    <img src={elevator} style={{ width: "40px", height: "40px" }} />
                  </Stack>
                  <ListItemText>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "right" }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ color: "#000", fontSize: "16px", fontWeight: "700" }}>
                          {lift.lift_name}
                        </Typography>
                      </Box>
                      <Typography sx={{ color: "#727272", fontSize: "12px" }}>
                        Status:
                        <span style={{ color: getLiftStatusColor(lift.status), marginLeft: "5px", textTransform: 'capitalize' }}>
                          {lift.status}
                        </span>
                      </Typography>
                      <Typography sx={{ color: "#727272", fontSize: "12px" }}>
                        Connected:
                        <span style={{ color: lift.connected ? "#12B76A" : "#FF0000", marginLeft: "5px" }}>
                          {lift.connected ? "Online" : "Offline"}
                        </span>
                      </Typography>
                      {/* <Typography sx={{ color: "#727272", fontSize: "12px" }}>
                        {`Current Floor: ${job.current_floor}`}
                      </Typography> */}
                      {/* { job.robots && job.robots.length > 0 && (
                        <Box sx={{ padding: '8px', borderRadius: '6px', mt: '4px', border: `1px solid ${COLOR.gray[300]}`, backgroundColor: COLOR.gray[100], color: COLOR.black}}>
                          {job.robots.map((robot, index) => (
                            <Typography key={index} sx={{ color: "#727272", fontSize: "12px" }}>
                              {`${robot.name} ${getStatusLabel(robot.status, robot)}`}
                            </Typography>
                          ))}
                        </Box>
                      )} */}
                      {lift.lift_job && lift.lift_job.length > 0 && filterRobotJobs(lift.lift_job || []).map((job, index) => (
                        <Typography key={index} sx={{ color: "#727272", fontSize: "12px" }}>
                          {getStatusLabel(job.lift_job_status, job)}
                        </Typography>
                      ))}
                    </Box>
                  </ListItemText>
                </ListItem>
                {index < generalInfo.lift.length - 1 && <Divider sx={{ width: "85%", margin: "auto", }} />}
              </>
            ))}
          </List>
          {!isLoadingLiftStatus && liftStatuses.length === 0 && (
            <Box sx={{ mt: '-20px', height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography style={{ color: COLOR.gray[400] }}>No data available</Typography>
            </Box>
          )}
        </Box>
      </>

    </Stack>
  );
}

export default GeneralStatus;