import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Button, Card, Pagination, Skeleton, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import robotBusy from '../../../assets/robotBusy.png'; 
import flashbot from '../../../assets/flashbot.png'; 
import swiftbot from '../../../assets/swiftbot.png'; 

import PlaceIcon from '@mui/icons-material/Place';
import salad from '../../../assets/salad.png'; 
import checked from '../../../assets/checked.png';
import { robotDetails, listOfJobs } from "../../../utils/DummyData";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import axios from "axios";
import { getRobotStatusMainColor, getStatusColor } from "../../../utils/ColourStatus";
import { JobData2, JobDetails3, RobotInfo3 } from "../../../store/types";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentRobotJobsPageNumber } from "../../../store/page/jobPageSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { useListMap } from "../../../hooks/map";
import { COLOR } from "../../../utils/colors";
import { VIEW } from "../../../utils/breakpoint";
import dayjs from "dayjs";

interface RobotStatusProps {
    showRobotStatus: boolean;
    // handleRobotTab: () => void;
    handleSelectedJob: (job: JobDetails2) => void;
    robotInfo: RobotInfo;
    handleCloseTab: () => void;
    // robotInfo: RobotDetails;
    handleBackButton: () => void;
    getRobotJobs: (robot_id: string, page?: number) => void;
    hasMoreRobotJobs: boolean;
}
interface RobotPose {
    latitude: number;
    longitude: number;
    orientation: number;
}

interface RobotStatus2 {
    battery: number;
    can_assign_job: boolean;
    is_online: boolean;
    map: string;
    organisation: string;
    pose: RobotPose;
    robot_id: string;
    robot_state: string;
    robot_type: string;
    timestamp: number;  
    robot_name: string;
    robot_serial_number: string;
    map_uuid?: string;
    layout_uuid?: string;
}

interface GeneralRobotInfo{
    robot_name: string;
    robot_mac: string;
    robot_serial_number: string;
    robot_type: string;
    organisation: string;
}

interface RobotInfo {
    robotStatus?: RobotStatus2;
    jobs?: JobData;
    generalInfo: GeneralRobotInfo;
}

interface RobotsData {
    [robot_id: string]: RobotInfo;
}

interface JobData {
    [job_id: string]: JobDetails2;
}

interface JobDetails {
    orderNo: string;
    timeStamp: string;
    status: string;
    pickUp: string;
    dropOff: string;
    estimatedTime: string;
    currentLocation: string;
    robotName: string;
    robotMacAddr: string;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
    recurring_type: string;
}

const RobotStatus: React.FC <RobotStatusProps> = ({ showRobotStatus, handleSelectedJob, robotInfo, handleCloseTab, handleBackButton, getRobotJobs, hasMoreRobotJobs }) => {
    useEffect(() => {
        console.log("robot job updated", robotInfo.jobs);
        console.log("robot page number", currentPageNumber, totalJobs, totalNumberOfPages);
        console.log("robots", robotInfo);
    }, [robotInfo]);

    const { data: mapData } = useListMap()
    const maps = mapData ? mapData.data : []

    const [isShowContent, setIsShowContent] = useState(false)

    useEffect(() => {
      setIsShowContent(showRobotStatus)
    }, [showRobotStatus])

    const currentPageNumber = useSelector((state: any) => state.jobPage.currentRobotJobsPageNumber);
    const totalJobs = useSelector((state: any) => state.jobPage.totalRobotJobs);
    const totalNumberOfPages = useSelector((state: any) => state.jobPage.totalRobotJobsPageNumber);
    const dispatch = useDispatch();
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch ( setCurrentRobotJobsPageNumber(value) );
    }

    const fetchMoreJobs = () => {
        console.log("Fetching more jobs 2", currentPageNumber + 1, totalNumberOfPages, hasMoreRobotJobs);
        if (hasMoreRobotJobs && currentPageNumber + 1 <= totalNumberOfPages) {
            console.log("Fetching more jobs", currentPageNumber + 1, totalNumberOfPages, hasMoreRobotJobs);
            getRobotJobs(robotInfo.robotStatus?.robot_id || "", currentPageNumber + 1);
        }
    }

    const selectedMap = maps.find((item) => [robotInfo.robotStatus?.map_uuid, robotInfo.robotStatus?.layout_uuid].includes(item.map_uuid))

    const statusTextColor = (status?: string) => getRobotStatusMainColor(status)
    
    return ( 
        <Stack
            sx={(theme) => ({
              [theme.breakpoints.up(VIEW.PHONE)]: {
                position: 'fixed',
                inset: 0,
                height: '100vh',
                width: '100vw',
                zIndex: 1100,
                marginTop: '0px !important'
              },
              [theme.breakpoints.up(VIEW.TABLET)]: {
                position: "absolute",
                top: isShowContent ? "225px" : "225px",
                left:'auto',
                bottom: 'auto',
                right: "20px",
                width: "400px",
                height: "54vh",
                maxHeight: "450px",
                zIndex: 1005,
              },
              [theme.breakpoints.up(VIEW.DESKTOP)]: {
                top: isShowContent ? "235px" : "220px",
                height: "450px",
                marginTop: '16px !important'
              },
                opacity: isShowContent ? 1 : 0,
                bgcolor: "white",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                overflowY: "auto",
                overflowX: "hidden",
                transition: 'all',
                transitionDuration: '300ms'
            })}>
                <Box sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",  height: "300px", display: "flex", flexDirection: "column" }}>
                    <Stack
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "10px",
                            m:1.5,
                        }}>
                            <Stack sx={{
                                position: "absolute",
                                top: "15px",
                                left: "15px",
                            }}>
                                <IconButton onClick={handleBackButton}>
                                    <ArrowBackIosNewIcon sx={{ color: "#000", fontSize:"15px" }} />
                                </IconButton>
                            </Stack>
                        <Stack sx={{width: "60px", height:"60px", bgcolor: "#f5f5f5", color: "white", fontWeight:"600", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center", ml:4 }}>
                            <img src={swiftbot} style={{ width: "40px", height: "40px" }} />
                            <Stack sx={{
                                position: "absolute",
                                top: "10px",
                                left: "90px",
                                bgcolor: "white",
                                color: "white",
                            }}>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width:"30px", height:"30px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius:"15px", bgcolor:"#fff", border: "1px solid #E6E6E6", }}>
                                    <img src={robotInfo.robotStatus?.robot_state === "Delivering"? robotBusy: checked} style={{ width: "20px", height: "20px" }} />
                                </Box>
                            </Stack>
                        </Stack>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", m:1, ml:4 }}>
                            <Typography sx={{ color: "#000", fontSize:"20px", fontWeight:"600" }}>
                                {robotInfo.robotStatus?.robot_name ? robotInfo.robotStatus?.robot_name : "Flashbot"}
                            </Typography>
                            <Typography sx={{ color: "#000", fontSize:"12px" }}>
                                {robotInfo.robotStatus?.robot_id}
                            </Typography>
                        </Box>
                        <Stack sx={{
                            position: "absolute",
                            top: "15px",
                            right: "15px",
                        }}>
                            <IconButton onClick={handleCloseTab}>
                                <CloseIcon sx={{ color: "#000", fontSize:"20px" }} />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", m:1, ml:4, mr:4, mt:-0.5 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                            <Typography sx={{ color: "#9F9F9F", fontSize:"12px" }}>
                                Battery Level
                            </Typography>
                            <Typography sx={{ color: "#000", fontSize:"14px" }}>
                                {robotInfo.robotStatus?.battery}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                            <Typography sx={{ color: "#9F9F9F", fontSize:"12px" }}>
                                Status
                            </Typography>
                            <Typography sx={{ color: statusTextColor(robotInfo.robotStatus?.robot_state), fontSize:"14px" }}>
                                {robotInfo.robotStatus?.robot_state !== "free" && robotInfo.robotStatus?.robot_state !== "Free" ? robotInfo.robotStatus?.robot_state.replace(/_/g, " ").split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") : "Idle" } 
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                            <Typography sx={{ color: "#9F9F9F", fontSize:"12px" }}>
                                Connectivity
                            </Typography>
                            <Typography sx={{ color: getRobotStatusMainColor(robotInfo.robotStatus?.is_online === true ? "online" : "offline") , fontSize:"14px" }}>
                                {robotInfo.robotStatus?.is_online ? "Online" : "Offline"}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{display: "flex", flexDirection: "row", ml: 3, mt:2, alignItems: selectedMap ? "start" : "center" }}>
                        <PlaceIcon sx={{ color: "#9F9F9F", fontSize:"20px", mr: 1.5, mt: 0.2 }} />
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                            {selectedMap && (
                              <Typography sx={{ color: "#000", fontSize:"14px" }}>
                                  {selectedMap.map_name}
                              </Typography>
                            )}
                            <Typography sx={{ color: "#000", fontSize:"12px" }}>
                                {`Latitude: ${robotInfo.robotStatus?.pose.latitude.toFixed(6)}, Longitude: ${robotInfo.robotStatus?.pose.longitude.toFixed(6)}`}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "row", ml: 3, mt:2, alignItems: selectedMap ? "start" : "center" }}>
                      <Typography sx={{ color: COLOR.gray[500], fontSize:"12px", fontStyle: 'italic'}}>
                        {`Last Updated: ${(robotInfo.robotStatus?.timestamp) ? dayjs.unix(robotInfo.robotStatus?.timestamp/(robotInfo.robotStatus.timestamp >= 1000000000 ? 1000 : 1)).format('DD MMM YYYY, hh:mm a') : "-"}`}
                      </Typography>
                    </Box>
                </Box>
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.up(VIEW.PHONE)]: {
                      height: "calc(100vh - 230px)"
                    },
                    [theme.breakpoints.up(VIEW.TABLET)]: {
                      height: "230px"
                    },
                    overflowY: "auto",
                    overflowX: "hidden"
                  })}
                  id="scrollableDiv">
                <InfiniteScroll
                    dataLength={Object.values(robotInfo.jobs || {}).length}
                    next={fetchMoreJobs}
                    hasMore={hasMoreRobotJobs}
                    loader={
                        <Box sx={{ padding: 2 }}>
                            <Skeleton variant="rectangular" height={50} sx={{ marginBottom: 1 }} />
                            <Skeleton variant="rectangular" height={50} sx={{ marginBottom: 1 }} />
                            <Skeleton variant="rectangular" height={50} />
                        </Box>
                    }
                    endMessage={
                        <Typography sx={{ textAlign: 'center', mt: 2 }}>
                            No more Jobs
                        </Typography>
                    }
                    scrollableTarget="scrollableDiv"
                >


                        <Typography sx={{ color: "#000", fontSize:"16px", fontWeight: "600", ml: 4, mt: 1 }}>
                            Jobs
                        </Typography>
                        <List>
                            {robotInfo.jobs &&  Object.values(robotInfo.jobs || {}).sort((a, b) => b.timestamp - a.timestamp).map((job: JobDetails2, index) => (
                                <>
                                    <ListItem key={index} sx={{ py: 1, '&:hover': { backgroundColor: '#f4f4f4', cursor: 'pointer' }, paddingLeft: "35px"}} onClick = {() => handleSelectedJob(job)}>
                                        <ListItemIcon >
                                        <Stack sx={{mr:2, width: "60px", height:"60px", bgcolor: "#f5f5f5", color: "white", fontWeight:"600", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center" }}>
                                            <img src={swiftbot} style={{ width: "40px", height: "40px" }} />
                                        </Stack>
                                            {/* <Box sx={{backgroundColor:"#E6E6E6", borderRadius:"20px", width:"35px", height:"35px"}}>
                                            <div style={{display:"flex", justifyContent:"center", alignItems:"center", padding:"3px"}}>
                                                <img src={swiftbot} style={{ width: "30px", height: "30px", margin:"auto" }} />
                                            </div>
                                            </Box> */}
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "right" }}>
                                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    <Typography sx={{ color: "#000", fontSize:"16px", fontWeight: "500" }}>
                                                        {/* Job #{job.order_number.slice(-5).toUpperCase()} */}
                                                        {job.order_number}
                                                    </Typography>
                                                    <Typography sx={{ color: "#727272", fontSize:"12px" }}>
                                                        {/* convert date time to date and pm/am */}
                                                        {new Date(job.timestamp*1000).toLocaleDateString()}, {new Date(job.timestamp*1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                                        
                                                    </Typography>
                                                </Box>
                                                <Typography sx={{ color: getStatusColor(job.job_status), fontSize:"12px" }}>
                                                    {job.job_status.replace(/_/g, " ").split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                                </Typography>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>
                                    {index < Object.values(robotInfo.jobs || {}).length - 1 && <Divider />}
                                </>
                                ))}
                        </List>
                        {/* <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                            <Pagination 
                                count={totalNumberOfPages}
                                page={currentPageNumber} 
                                onChange={handlePageChange} 
                                color="primary" 
                            />
                        </Box> */}
                    </InfiniteScroll>
                </Box>
        </Stack>
     );
}
 
export default RobotStatus;