import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import dayjs from "dayjs";
import { TimeNumberProps } from "./_type";
import { COLOR } from "../../../utils/colors";
import { useEffect } from "react";
import { useLiftStats } from "../../../hooks/lift";
import { sumOf } from "../../scheduledJob/_function/common";

export default function LiftSuccessRate({
  startTime,
  endTime
}: TimeNumberProps) {
  const time = dayjs.unix(endTime).diff(dayjs.unix(startTime), 'days')

  const { data: dataLift, isLoading, refetch } = useLiftStats({
    start_time: startTime,
    end_time: dayjs.unix(endTime).add(1, 'day').unix()
  })

  const rawLiftStats = dataLift ? Object.values(dataLift.daily_stats) : []
  const liftStats = rawLiftStats.filter((data) => data.start_time >= startTime && data.start_time <= endTime)

  const noRetries = dataLift && dataLift.daily_stats ? sumOf(liftStats.map((item) => item.no_retries)) : 0;
  const retries = dataLift && dataLift.daily_stats ? sumOf(liftStats.map((item) => item.retries)) : 0;
  const total = noRetries + retries || dataLift?.total || 0

  const calcPercentage = (value: number) => parseFloat(((value / total) * 100).toFixed(2))
  useEffect(() => {
    refetch()
  }, [startTime, endTime])

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          width: "100%",
          flex: "auto",
          border: `1px solid ${COLOR.gray[400]}`
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            my: '8px',
            ml: '14px',
            mr: '8px',
            gap: '6px',
            height: "100%",
            flex: "auto",
          }}
        >
          <Typography className='font-poppins' variant="h6" sx={{ color: COLOR.black, fontSize: '16px' }}>
            Lift Success Rate
          </Typography>
          <Typography variant="h6" sx={{ color: "#9E9E9E", fontSize: '12px', mt: '-4px' }}>
            {time === 1 ? 'Yesterday' : time === 0 ? 'Today' : dayjs.unix(startTime).format('DD/MM/YY') + ' - ' + dayjs.unix(endTime).format('DD/MM/YY')}
          </Typography>
          {isLoading && (
            <CircularProgress />
          )}
          {(noRetries > 0|| retries > 0) && (
            <PieChart
              skipAnimation
              series={[
                {
                  innerRadius: 45,
                  outerRadius: 80,
                  arcLabel: (item) => item.value > 0 ? `${calcPercentage(item.value)}%` : '',
                  data: [
                    { id: 1, value: noRetries, label: 'No Retry', color: '#7cbae0' },
                    { id: 2, value: retries, label: '>= 1 Retry', color: '#fa9ba9' },
                  ],
                },
              ]}
              height={200}
            />
          )}
          {!isLoading && (!noRetries && !retries) && (
            <Box sx={{ width: '100%', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" sx={{ fontSize: '12px' }}>
                No Data to Display
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Grid>
  )
}