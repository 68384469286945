import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Avatar, IconButton, Box } from '@mui/material';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import MonitorIcon from '@mui/icons-material/Monitor';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import autove from '../../assets/autove.jpeg';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setFalse } from '../../store/deliveryDashboardSlice';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { setSenderLocation, setRecipientLocation } from '../../store/map/mapSlice';
import { setIsSelectedRobot } from '../../store/robot/robotSlice';
import { deleteRecipientLocation } from '../../store/job/formSlice';
import GroupsIcon from '@mui/icons-material/Groups';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reset = () => {
    toggleSidebar();
    dispatch(setIsSelectedRobot(false));
    dispatch(setFalse());
    dispatch(setSenderLocation(""));
    dispatch(setRecipientLocation(""));
    dispatch(deleteRecipientLocation());
  }

  const items = [{
    name: 'Dashboard',
    icon: <MonitorIcon />,
    click: () => {
      navigate('/');
      reset();
    }
  }, {
    name: 'Jobs',
    icon: <WorkHistoryIcon />,
    click: () => {
      navigate('/jobs');
      reset();  
    }
  }, 
  {
    name: 'Reports',
    icon: <AssessmentIcon />,
    click: () => {
      navigate('/reports');
      reset();  
    }
  },
  {
    name: 'Access Control',
    icon: <GroupsIcon />,
    click: () => {
      navigate('/access-control');
      reset();  
    }
  }, {
    name: 'Robots',
    icon: <SmartToyIcon />,
    click: () => {
      navigate('/robot');
      reset();  
    }
  }];
  

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={toggleSidebar}
      sx={{
        backgroundColor: '#fff',
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: 240, boxSizing: 'border-box' },
      }}
    >
      <Toolbar >
      <IconButton
          onClick={toggleSidebar}
          sx={{ marginLeft: 'auto' }} 
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 50px 0px' }}>
        <img src={autove} alt="logo" style={{ width: 70, height: 70, borderRadius:"10px" }} />
      </div>
      <List>
        {items.map((item, index) => (
          <ListItem button key={index} onClick={item.click}>
            <ListItemIcon sx={{ml:2}}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
