import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import dayjs from "dayjs";
import { TimeNumberProps } from "./_type";
import { COLOR } from "../../../utils/colors";
import { RobotTaskType } from "../../../hooks/robotTask/index.type";
import { chartLabel } from "../../../utils/styles";

export default function SSUAverageTime({
  startTime,
  endTime,
  data,
  isLoading
}: TimeNumberProps & { data: RobotTaskType[], isLoading: boolean }) {
  const time = dayjs.unix(endTime).diff(dayjs.unix(startTime), 'days')

  const filteredData = (data || []).filter(
    (i) =>
      i.from_location.includes('SSU') ||
      i.from_location.includes('Swiftie1Charger') ||
      i.from_location.includes('Swiftie2Charger')
  )
  const listData = filteredData.filter((i) => i && i.duration && i.duration.toString() !== '-1')

  const moreThanHour = listData.filter((item) => {
    const duration = item.duration.toString();
    const minutes = parseInt(duration.split(':')[0])

    return minutes >= 60
  }).length

  const lessThanHour =  listData.filter((item) => {
    const duration = item.duration.toString();
    const minutes = parseInt(duration.split(':')[0])

    return minutes < 60
  }).length

  const total = moreThanHour + lessThanHour
  const calcPercentage = (value: number) => parseFloat(((value / total) * 100).toFixed(2))

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          width: "100%",
          flex: "auto",
          border: `1px solid ${COLOR.gray[400]}`
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            my: '12px',
            ml: '6px',
            mr: '14px',
            gap: '6px'
          }}
        >
          <Typography className='font-poppins' variant="h6" sx={{ color: COLOR.black, fontSize: '16px' }}>
            SSU Average Time
          </Typography>
          <Typography variant="h6" sx={{ color: "#9E9E9E", fontSize: '12px', mt: '-4px' }}>
            { time === 1 ? 'Yesterday' : time === 0 ? 'Today' : dayjs.unix(startTime).format('DD/MM/YY') + ' - ' + dayjs.unix(endTime).format('DD/MM/YY')}
          </Typography>
          {isLoading && (
            <CircularProgress />
          )}
          {listData.length > 0 && (
            <PieChart
              skipAnimation
              series={[
                {
                  innerRadius: 45,
                  outerRadius: 80,
                  arcLabel: (item) => item.value > 0 ? `${calcPercentage(item.value)}%` : '',
                  data: [
                    { id: 1, value: lessThanHour, label: '< 60mins', color: COLOR.graph.blue },
                    { id: 2, value: moreThanHour, label: '>= 60 mins', color: COLOR.graph.red },
                  ],
                },
              ]}
              height={200}
              sx={{ ...chartLabel }}
            />
          )}
          {!isLoading && listData.length <= 0 && (
            <Box sx={{ width: '100%', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Typography variant="h6" sx={{ fontSize: '12px' }}>
                No Data to Display
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Grid>
  )
}