import React, { CSSProperties } from 'react';
import { Box, Grid, Card, Typography, Divider, useMediaQuery, useTheme } from '@mui/material';
import robots from '../../../assets/new_icons/robot.png';
import idle from '../../../assets/new_icons/idle.png';
import busy from '../../../assets/new_icons/busy.png';
import offline from '../../../assets/new_icons/offline.png';
import job from '../../../assets/job.png';
import { COLOR } from '../../../utils/colors';
import { VIEW } from '../../../utils/breakpoint';

interface AggregateCardProps {
  title: string;
  data: number;
  clicked: boolean;
}

const robotStyle: CSSProperties = {
  aspectRatio: '112/94',
}

const idleStyle: CSSProperties = {
  aspectRatio: '90/83',
}

const busyStyle: CSSProperties = {
  aspectRatio: '96/96',
}

const offlineStyle: CSSProperties = {
  aspectRatio: '67/80',
}

const AggregateCard: React.FC<AggregateCardProps> = ({ title, data, clicked }) => {
  const getIconStyles = (title: string): CSSProperties => {
    switch (title) {
      case 'Total Robots':
        return robotStyle

      case 'Idle':
        return idleStyle

      case 'Busy':
        return busyStyle

      case 'Offline':
        return offlineStyle

      default:
        return {
          aspectRatio: '1/1'
        }
        break;
    }
  }
  const theme = useTheme();
  const isLessThanSM = useMediaQuery(theme.breakpoints.down(VIEW.TABLET));

  const getTitle = (text: string) => {
    const splittedBySpace = text.split(" ")
    if (isLessThanSM && splittedBySpace.length > 1) {
      return splittedBySpace[0]
    }
    return text
  }
  return (
    <Grid item xs={3} md={3} sx={{ mt: -4 }}>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          bgcolor: clicked ? "rgba(24, 26, 51, 0.8)" : "white",
          cursor: "pointer",
          width: "100%",
          boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.05)",
          '&:hover': {
            boxShadow: "1px 3px 3px 1px rgba(0, 0, 0, 0.075)",
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            my: '12px',
            ml: '14px',
            mr: '24px',
            gap: '6px'
            // mr:2, 
            // height: "60px",
          }}
        >
          <Typography
            className='font-poppins'
            variant="h6"
            sx={(theme) => ({
              [theme.breakpoints.up(VIEW.PHONE)]: {
                fontSize: '12px'
              },
              [theme.breakpoints.up(VIEW.TABLET)]: {
                fontSize: '14px'
              },
              [theme.breakpoints.up(VIEW.DESKTOP)]: {
                fontSize: '16px'
              },
              color: "#9E9E9E",
            })}
          >
            {getTitle(title)}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'space-between' }}>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up(VIEW.PHONE)]: {
                  display: 'none'
                },
                [theme.breakpoints.up(VIEW.TABLET)]: {
                  display: 'inline-block',
                  height: '24px',
                },
                [theme.breakpoints.up(VIEW.DESKTOP)]: {
                  height: '50px',
                },
              })}>
              <img
                src={
                  title === "Total Robots"
                    ? robots
                    : title === "Idle"
                      ? idle
                      : title === "Busy"
                        ? job
                        : offline
                }
                alt="icon"
                style={{
                  ...getIconStyles(title),
                  height: '100%',
                }}
              />
            </Box>
            <Typography variant="h4" sx={(theme) => ({
              [theme.breakpoints.up(VIEW.PHONE)]: {
                marginLeft: "0px",
                mt: 'auto',
                fontSize: '24px'
              },
              [theme.breakpoints.up(VIEW.TABLET)]: {
                fontSize: '24px'
              },
              [theme.breakpoints.up(VIEW.DESKTOP)]: {
                marginLeft: "30px",
                mt: 1,
                fontSize: '34px'
              },
              color: COLOR.black,
            })} >
              {data}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

export default AggregateCard;
