import React, { useEffect, useState } from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, TablePagination, IconButton, Collapse, Box, CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axios from "axios";
import { useDispatch } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import { RobotTaskType } from '../../../hooks/robotTask/index.type';
import { OrderDetailType } from './_type';
import { underscoreToSpace } from '../../../utils/utils';
import SquareTag from '../../../components/SquareTag';
import { ArrowForward, LogoDev } from '@mui/icons-material';
import { COLOR } from '../../../utils/colors';
import { renderDuration } from '../../../lib/api/function';
import StatusChip, { statusColorMap } from '../../../components/StatusChip';

interface JobTableProps {
  actions?: string[];
  type?: string;
  columns: Column[];
  items: RobotTaskType[];
  getJobList: (pageNumber: number, pageSize: number) => void;
  page: number;
  totalData?: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean
}

interface Column {
  id: string;
  label: string;
  padding?: string;
}

interface ProcessedJobDetails {
  id: string;
  order_number: string;
  job_type: string;
  robot_name: string;
  robot_id: string;
  load_compartment: string;
  sender_location: string;
  recipient_location: string;
  duration: string;
  status: string;
  created_at: number;
  timestamp: number;
  details: detailed[];
}

interface detailed {
  order_number: string;
  event: {
    robot_mac: string;
    event_type: string;
    created_time: number;
    remarks: string;
  };
  from_location: string;
  to_location: string;
  start_time: number;
  completed_time: number;
  status: string
}

const JobTable: React.FC<JobTableProps> = ({ columns, items, getJobList, isLoading, page, totalData, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) => {
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set()); // Track multiple expanded rows
  const [expandedOrderRows, setExpandedOrderRows] = useState<Set<string>>(new Set()); // Track multiple expanded rows
  const [jobEvents, setJobEvents] = useState<{ [key: string]: detailed[] }>({}); // Store events per job
  const [orders, setOrders] = useState<{ [key: string]: OrderDetailType[] }>({});
  const dispatch = useDispatch();
  const [pageDetailNumber, setDetailPageNumber] = useState<{ [key: string]: number }>({});
  const [hasMoreDetails, setHasMoreDetails] = useState<{ [key: string]: boolean }>({});

  const [eventPageNumber, setEventPageNumber] = useState<{ [key: string]: number }>({});
  const [hasMoreEvents, setHasMoreEvents] = useState<{ [key: string]: boolean }>({});

  const [isLoadingOrder, setIsLoadingOrder] = useState(false)
  const [isLoadingEvent, setIsLoadingEvent] = useState(false)

  const handleExpandClick = (jobId: string) => {
    const updatedExpandedRows = new Set(expandedRows);
    if (updatedExpandedRows.has(jobId)) {
      updatedExpandedRows.delete(jobId); // Collapse if already expanded
    } else {
      updatedExpandedRows.add(jobId); // Expand new row
      fetchJobDetails(1, 25, jobId.toString(), true); // Fetch job details for this job, reset the details
    }
    setExpandedRows(updatedExpandedRows);
  };

  const handleExpandOrderClick = (orderId: string) => {
    const updatedExpandedOrderRows = new Set(expandedOrderRows);
    if (updatedExpandedOrderRows.has(orderId)) {
      updatedExpandedOrderRows.delete(orderId); // Collapse if already expanded
    } else {
      updatedExpandedOrderRows.add(orderId); // Expand new row
      fetchOrderDetails(1, 25, orderId.toString(), true); // Fetch job details for this job, reset the details
    }
    setExpandedOrderRows(updatedExpandedOrderRows);
  };

  const defaultHeaderStyle = {
    fontSize: "14px",
    color: "grey",
    padding: "15px 50px",
    fontWeight: "bold",
    backgroundColor: "#F5F9FF",
    whiteSpace: "nowrap"
  };

  // Render function for the header
  const renderHeader = (column: Column) => {
    return {
      ...defaultHeaderStyle,
      padding: column.padding ? column.padding : defaultHeaderStyle.padding
    };
  };

  const fetchJobDetails = async (pageNumber: number, pageSize: number, taskId: string, reset = false) => {
    try {
      setIsLoadingOrder(true)
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/task/delivery/${taskId}/?page=${pageNumber}&page_size=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      setIsLoadingOrder(false)

      if (response.status === 200) {
        const resData = response.data;
        const fetchedData = resData?.task_details?.order_details || []

        setOrders(prev => ({
          ...prev,
          [taskId]: reset ? fetchedData : [...(prev[taskId] || []), ...fetchedData]
        }));
        setDetailPageNumber(prev => ({ ...prev, [taskId]: pageNumber }));
        setHasMoreDetails(prev => ({
          ...prev,
          [taskId]: pageNumber < response.data.num_pages
        }));
      }
    } catch (error) {
      setIsLoadingOrder(false)
      console.error('Error fetching job details:', error);
    }
  };

  const fetchOrderDetails = async (pageNumber: number, pageSize: number, orderNumber: string, reset = false) => {
    try {
      setIsLoadingEvent(true)
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_details/?order_number=${orderNumber}&page=${pageNumber}&page_size=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      setIsLoadingEvent(false)

      if (response.status === 200) {
        const resData = response.data;
        const fetchedData = resData?.data || []
        console.log(resData, fetchedData);

        setJobEvents(prev => ({
          ...prev,
          [orderNumber]: reset ? fetchedData : [...(prev[orderNumber] || []), ...fetchedData]
        }));
        setEventPageNumber(prev => ({ ...prev, [orderNumber]: pageNumber }));
        setHasMoreEvents(prev => ({
          ...prev,
          [orderNumber]: pageNumber < response.data.num_pages
        }));
      }
    } catch (error) {
      setIsLoadingEvent(false)
      console.error('Error fetching job details:', error);
    }
  };

  const loadMoreDetails = (orderNumber: string) => {
    const nextPage = (pageDetailNumber[orderNumber] || 1) + 1;
    fetchJobDetails(nextPage, 25, orderNumber);
  };

  const loadMoreEvents = (orderNumber: string) => {
    const nextPage = (eventPageNumber[orderNumber] || 1) + 1;
    fetchOrderDetails(nextPage, 25, orderNumber);
  };

  const formatDate = (timestamp: number) => {
    if (timestamp === -1) {
      return ""
    }
    const date = new Date(timestamp * 1000);
    const dayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' };
    const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    const day = date.toLocaleDateString('en-GB', dayOptions);
    const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
    const time = date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: '2-digit', hour12: true });

    return (
      <>
        <Typography variant="body2" component="span" sx={{ fontSize: "12px", textTransform: 'uppercase' }}>{time}</Typography>
        <br />
        <Typography variant="body2" component="span" sx={{ fontSize: "12px" }}>{day}, {formattedDate}</Typography>
      </>
    );
  };

  const isInDateFormat = (id: string) => {
    return ["created_at", "return_time_start", "return_time_completed"].includes(id)
  }

  const isDuration = (id: string) => ['duration'].includes(id)

  const renderTag = (tags: string[]) => {
    return (
      <div style={{ display: 'flex', gap: '2px', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        {tags.map((item) => (<SquareTag key={item}>{item}</SquareTag>))}
      </div>
    )
  }

  const renderRow = (info: any) => {
    const isExpanded = expandedRows.has(info.id);
    return (
      <React.Fragment key={info.id}>
        <TableRow
          sx={{ cursor: 'pointer', backgroundColor: "white", '&:hover': { backgroundColor: '#f7f7f7' } }}
          onClick={() => handleExpandClick(info.id)}
        >
          <TableCell padding="checkbox">
            <IconButton size="small" onClick={() => handleExpandClick(info.id)}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TableCell>
          {columns.map(column => {
            const value = info[column.id as keyof ProcessedJobDetails];
            return (
              <TableCell
                key={column.id}
                align="center"
                sx={{
                  fontSize: "12px",
                  textTransform: "capitalize"
                }}>
                {isInDateFormat(column.id) ? formatDate(info[column.id]) : column.id === 'duration' && info.duration === "-1"
                  ? ""
                  : isDuration(column.id) ? renderDuration(info.duration)
                  : column.id === 'orders' ? renderTag(info.orders || [])
                    : column.id === 'status'
                      // ? value.toString().split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase())
                      ? <StatusChip color={statusColorMap(value.toString().replace(/\btask\b/g, 'job'))}>{value.toString().split('_').join(' ').replace(/\btask\b/g, 'job')}</StatusChip>
                      : Array.isArray(value) ? JSON.stringify(value) : value}
              </TableCell>
            );
          })}
        </TableRow>
        {isExpanded && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1} sx={{ backgroundColor: "#f7f7f7", }}>
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Box margin={1} sx={{ width: "80%" }} id={`scrollable-${info.job_id}`}>
                  <Typography variant="h6" gutterBottom component="div">
                    Orders
                  </Typography>
                  {!isLoadingOrder && orders[info.job_id] && orders[info.job_id].length > 0 && (
                    <InfiniteScroll
                      dataLength={orders[info.job_id]?.length || 0}
                      next={() => loadMoreDetails(info.job_id)}
                      hasMore={hasMoreDetails[info.job_id] || false}
                      loader={
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                          <CircularProgress />
                        </Box>
                      }
                      scrollableTarget={`scrollable-${info.job_id}`}
                      endMessage={<Typography align="center" sx={{ fontSize: "12px", color: "#757575", fontStyle: "italic", mt: 1, mb: 1 }}>No more orders to show</Typography>}
                    >
                      <Table size="small" aria-label="events">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">Order No</TableCell>
                            <TableCell align="center">From</TableCell>
                            <TableCell align="center">To</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Duration</TableCell>
                            <TableCell align="center">Start Time</TableCell>
                            <TableCell align="center">Completed Time</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orders[info.job_id].map((order, index) => renderOrderRow(order, index))}
                        </TableBody>
                      </Table>
                    </InfiniteScroll>
                  )}
                  {!isLoadingOrder && (!orders[info.job_id] || orders[info.job_id].length <= 0) && (
                    <Typography variant="body2">No orders found</Typography>
                  )}
                  {isLoadingOrder && orders[info.job_id] && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                      <CircularProgress />
                    </Box>
                  )
                  }
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  };

  const renderOrderRow = (order: OrderDetailType, index: number) => {
    const isOrderExpanded = expandedOrderRows.has(order.job_id);
    return (
      <React.Fragment key={index}>
        <TableRow
          sx={{ cursor: 'pointer', backgroundColor: "white", '&:hover': { backgroundColor: '#f7f7f7' } }}
          onClick={() => handleExpandOrderClick(order.job_id)}
        >
          <TableCell padding="checkbox">
            <IconButton size="small" onClick={() => handleExpandOrderClick(order.job_id)}>
              {isOrderExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
            {order.job_id}
          </TableCell>
          <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
            {order.from_location}
          </TableCell>
          <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
            {order.to_location}
          </TableCell>
          <TableCell align="center" sx={{ fontSize: "12px", padding: "10px", textTransform: 'capitalize' }}>
            {/* {underscoreToSpace(order.status.replace(/\btask\b/g, 'job'))} */}
            <StatusChip color={statusColorMap(order.status.replace(/\btask\b/g, 'job'))}>
              {underscoreToSpace(order.status.replace(/\btask\b/g, 'job'))}
            </StatusChip>
          </TableCell>
          <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
            {order.duration.toString() !== '-1' && order.duration}
          </TableCell>
          <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
            {formatDate(order.created_time)}
          </TableCell>
          <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
            {order.completed_time === -1 ? "" : formatDate(order.completed_time)}
          </TableCell>
        </TableRow>
        {isOrderExpanded && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1} sx={{ backgroundColor: "#ffffff", }}>
              <Collapse in={isOrderExpanded} timeout="auto" unmountOnExit>
                <Box margin={1} paddingTop={'16px'} sx={{ width: "70%" }} id={`scrollable-event-${order.job_id}`} style={{ overflowY: "auto", maxHeight: "300px" }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Past Events for {order.job_id}
                  </Typography>
                  {!isLoadingEvent && jobEvents[order.job_id] && jobEvents[order.job_id].length > 0 && (
                    <InfiniteScroll
                      dataLength={jobEvents[order.job_id]?.length || 0}
                      next={() => loadMoreEvents(order.job_id)}
                      hasMore={hasMoreEvents[order.job_id] || false}
                      loader={
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                          <CircularProgress />
                        </Box>
                      }
                      scrollableTarget={`scrollable-event-${order.job_id}`}
                      endMessage={<Typography align="center" sx={{ fontSize: "12px", color: "#757575", fontStyle: "italic", mt: 1, mb: 1 }}>No more events to show</Typography>}
                    >
                      <Table size="small" aria-label="events">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Event Type</TableCell>
                            <TableCell align="center">Current Location</TableCell>
                            <TableCell align="center">Reported Time</TableCell>
                            {/* <TableCell align="center">Completed Time</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {jobEvents[order.job_id].map((event, idx) => (
                            <TableRow key={idx}>
                              <TableCell align="center" sx={{ fontSize: "12px", padding: "10px", textTransform: 'capitalize' }}>
                                {underscoreToSpace(event.event.event_type)}
                              </TableCell>
                              <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
                                {event.from_location}
                              </TableCell>
                              <TableCell align="center" sx={{ fontSize: "12px", padding: "10px" }}>
                                {formatDate(event.start_time)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </InfiniteScroll>
                  )}
                  {!isLoadingEvent && (!jobEvents[order.job_id] || jobEvents[order.job_id].length <= 0) && (
                    <Typography variant="body2">No events found</Typography>
                  )}
                  {isLoadingEvent && jobEvents[order.job_id] && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                      <CircularProgress />
                    </Box>
                  )
                  }
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    )
  }

  // Map items to match ProcessedJobDetails
  const processedItems: any[] = items.map((job: RobotTaskType) => ({
    id: job.job_id.toString(),
    order_number: job.job_id.toString(),
    job_id: job.job_id.toString(),
    robot_name: job.robot_name || "",
    robot_id: job.robot_id,
    duration: job.duration.toString(),
    status: job.status,
    created_at: job.created_time,
    from_location: job.route && job.route.length > 0 ? (
      <div className='flex items-center'>
        { job.route.map((route, index) => (
          <div key={index} className='flex items-center'>
            <span style={{ wordBreak: 'keep-all'}}>{ route }</span>
            {(job.route && index + 1 < job.route?.length) && (
              <ArrowForward fontSize='small' sx={{ mx: '4px', color: COLOR.gray[500]}}/>
            )}
          </div>
        ))}
      </div>
    ) : '',
    number_failed_deliveries: job.number_failed_deliveries || 0,
    orders: job.orders,
    return_time_start: job.return_time_start,
    return_time_completed: job.return_time_completed,
    error: '-',
    details: [], // Make sure to have details initialized if not already
  }));

  return (
    <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', height: "620px", borderRadius: "10px", border: "1px solid #E0E0E0", m: '20px auto' }}>
      <TableContainer sx={{ backgroundColor: "#F5F5F5", alignItems: "center", overflowX: "auto" }}>
        <Table stickyHeader>
          <TableHead sx={{ width: "100%", margin: "auto" }}>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#F5F9FF", width: "50px", borderRadius: "10px", }} />
              {columns.map(column => (
                <TableCell key={column.id} align="center" sx={{ ...renderHeader(column) }}>
                  {column.label}
                </TableCell>
              ))}

            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {processedItems.map(info => renderRow(info))}
            </>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20]}
        component="div"
        count={totalData || 0}
        rowsPerPage={rowsPerPage}
        page={page > 0 ? page - 1 : 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ flexShrink: 0, boxShadow: "none" }}
      />
    </Paper>
  );
};

export default JobTable;
