import React, { useEffect, useState } from 'react';
import { Box, Typography, AppBar, Toolbar, Icon, IconButton, Badge, Menu, MenuItem, ListItemText, Drawer } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomAvatar from '../CustomAvatar';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import { toggle, setTrue, setFalse } from '../../store/deliveryDashboardSlice';
import { useWebSocket } from '../useWebSocket';
import GroupsIcon from '@mui/icons-material/Groups';
import { setNotificationHistoryPanel } from '../../store/notification/notificationSlice';
import NotificationHistoryPanel from '../../routes/dashboard/components/NotificationHistoryPanel';
import { useDispatch, useSelector } from 'react-redux';
import { JobDetails2, JobData } from '../../store/types';
import Dropdown from '../dropdown';
import autove from '../../assets/autove.jpeg';
import { setIsSelectedRobot } from '../../store/robot/robotSlice';
import { useMe } from '../../hooks/user';
import { Close, Phone } from '@mui/icons-material';
import { VIEW } from '../../utils/breakpoint';
import { smoothTransition } from '../../utils/animate';
import { COLOR } from '../../utils/colors';
import DropdownMobile from '../dropdown/mobile';
import { useAuth } from '../../context/AuthContext';

interface TopBarProps {
  handleIsOpen: () => void;
  handleNotificationDisplay: () => void;
  notificationCount: number;
  toggleSidebar: () => void;
}

type RouteMenuType = {
  route?: string,
  label: string,
  pageName: string,
  children?: {
    route?: string,
    label: string,
    pageName: string,
  }[]
}

const TopBar: React.FC<TopBarProps> = ({ handleIsOpen, handleNotificationDisplay, notificationCount, toggleSidebar }) => {
  const isOpen = useSelector((state: any) => state.deliveryDashboard.isOpen);
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState("");
  const [profile, setProfile] = useState<{ username: string } | null>(null);
  const location = useLocation();
  const [isDashboard, setIsDashboard] = useState(false);
  const dispatch = useDispatch();
  const dummyNotificationHistory: JobData = {};
  const [currentPage, setCurrentPage] = useState<string>('');

  useEffect(() => {
    console.log("location", location);
    if (location.pathname === '/') {
      setCurrentPage('Dashboard');
      setIsDashboard(true);
    }
    else {
      console.log("location 2", location.pathname.split('/')[1]);

      setCurrentPage(location.pathname.split('/')[1]);
      setIsDashboard(false);
    }
    console.log("isDashboard", isDashboard);
  }, [location]);

  useEffect(() => {
    // Load profile data from localStorage
    const loadProfile = () => {
      const storedProfile = localStorage.getItem('profile');
      if (storedProfile) {
        setProfile(JSON.parse(storedProfile));
      }
    };

    loadProfile();

    // Update current time every second
    const updateCurrentTime = () => {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");
      setCurrentTime(`${hours}:${minutes}:${seconds}`);
    };

    updateCurrentTime();
    const intervalId = setInterval(updateCurrentTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const notificationHistoryPanel = useSelector((state: any) => state.notification.notificationHistoryPanel);

  const handleSelectedJob2 = (job: JobDetails2) => {
    console.log("Selected Job:", job)

  }

  const handleDeleteNotification = (notificationId: string) => {
    console.log("Notification ID:", notificationId);
    // dispatch(deleteNotification(notificationId));
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const handleNavigation = (route: string) => {
    navigate(route);
  };

  const handleDeliveryClick = () => {
    isOpen ? dispatch(setFalse()) : dispatch(setTrue());
    if (location.pathname !== '/') {
      navigate('/');
    }
  }
  const [userName, setUserName] = useState<string>('');
  const [initials, setInitials] = useState<string>('');
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const { data: userMe } = useMe();

  useEffect(() => {
    if (!userMe) {
      return
    }
    setUserName(userMe.username)
    setInitials(userMe.username[0])
  }, [userMe]);

  const { logout } = useAuth();
  const routes: RouteMenuType[] = [
    {
      route: '', label: 'Applications', pageName: 'application',
      children: [
        { route: '/delivery', label: 'Delivery', pageName: 'delivery' },
      ]
    },
    { route: '/access-control', label: 'Users', pageName: 'access-control' },
    { route: '/robot', label: 'Robots', pageName: 'robots' },
    { route: '/orders', label: 'Orders', pageName: 'orders' },
    { route: '/jobs', label: 'Job', pageName: 'jobs' },
    { route: '/reports', label: 'Reports', pageName: 'reports' },
    { route: '/scheduled-job', label: 'Scheduled Job', pageName: 'scheduled-job' },
    // { label: 'Settings' , pageName : 'setting',
    //   children: [
    //     { route: '/users', label: 'User Management' , pageName : 'user-management'},
    //     { route: '/map', label: 'Map Config' , pageName : 'map-config'},
    //   ]
    // },
  ];

  const isCurrentChildRoute = (route: RouteMenuType) => {
    if (!route.children) {
      return false
    }
    return route.children.map((item) => item.route).includes(location.pathname)
  }

  return (
    <AppBar position="sticky" sx={{
      bgcolor: "white",
      boxShadow: "none",
      background: location.pathname === '/' ? "#DAFBEF" : "linear-gradient(to bottom, #DAFBEF, #F8F7F7)",
    }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '98%', margin: "auto" }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <IconButton onClick={toggleSidebar} sx={{ mr:4 }}>

                            <MenuIcon sx={{ color: "#444444", fontSize: "25px",}} />
                        </IconButton> */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', ml: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => { dispatch(setFalse()); dispatch(setIsSelectedRobot(false)); navigate('/') }}>
              <img src={autove} alt="logo" style={{ width: '30px', height: '30px', borderRadius: '8px', }} />
              <Typography
                variant="h6"
                sx={(theme) => ({
                  [theme.breakpoints.up(VIEW.PHONE)]: {
                    mr: 1,
                    fontSize: "20px"
                  },
                  [theme.breakpoints.up(VIEW.DESKTOP)]: {
                    mr: 4,
                    fontSize: "24px"
                  },
                  color: "#000",
                })}>
                RoboHub
              </Typography>
            </Box>
            {/* <Typography variant="h6" sx={{ color: "#444444", mr: 4, fontSize: "24px", '&:hover': { cursor: 'pointer',}}} onClick={() => navigate('/')}>
                            RoboHub
                        </Typography> */}

            <Box
              sx={(theme) => ({
                [theme.breakpoints.up(VIEW.PHONE)]: {
                  display: 'none'
                },
                [theme.breakpoints.up(VIEW.DESKTOP)]: {
                  display: 'flex'
                },
                alignItems: 'center'
              })}>
              {routes.map((route, index) => route.children && route.children.length > 0 ? (
                <Dropdown key={index} label={route.label} sx={{ color: isCurrentChildRoute(route) ? "#000" : "#A7A7A7" }}>
                  {route.children.map((subMenu, idx) => (
                    <MenuItem key={idx} onClick={() => subMenu.route === '/delivery' ? handleDeliveryClick() : subMenu.route && navigate(subMenu.route)}>
                      <ListItemText>{subMenu.label}</ListItemText>
                    </MenuItem>
                  ))}
                </Dropdown>
              ) : (
                <Typography key={index} variant="h6" sx={{ color: location.pathname.includes(route.route as string) ? "#000" : "#A7A7A7", mr: 4, fontSize: "14px", '&:hover': { cursor: 'pointer', color: "#4e4e4e" } }} onClick={() => route.route && navigate(route.route)}>
                  {route.label}
                </Typography>
              ))}
            </Box>
          </Box>


          {/* </Box> */}

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              sx={(theme) => ({
                [theme.breakpoints.up(VIEW.PHONE)]: {
                  mr: 0,
                  fontSize: '16px'
                },
                [theme.breakpoints.up(VIEW.DESKTOP)]: {
                  mr: 2,
                  fontSize: '20px'
                },
                color: "#444444"
              })}>
              {currentTime}
            </Typography>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down(VIEW.TABLET)]: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                },
                [theme.breakpoints.up(VIEW.DESKTOP)]: {
                  display: 'none'
                }
              })}
              onClick={() => setOpenMobileMenu(true)}
            >
              <IconButton
              >
                <MenuIcon sx={{ color: '#444444' }} />
              </IconButton>
            </Box>
            <IconButton
              onClick={() => dispatch(setNotificationHistoryPanel(true))}
              sx={(theme) => ({
                [theme.breakpoints.up(VIEW.PHONE)]: {
                  mr: 0
                },
                [theme.breakpoints.up(VIEW.TABLET)]: {
                  mr: 2
                }
              })}>
              <Badge badgeContent={notificationCount} color="primary">
                <NotificationsNoneIcon sx={{ color: "#444444" }} />
              </Badge>
            </IconButton>
            {/* <SettingsIcon sx={{ color: "#444444", ml: 2, mr: 2 }} /> */}
            {initials != "" && userName != "" && (
              <>
                <CustomAvatar initials={initials} />
                <Typography
                  variant="h6"
                  sx={(theme) => ({
                    [theme.breakpoints.up(VIEW.PHONE)]: {
                      display: 'none'
                    },
                    [theme.breakpoints.up(VIEW.TABLET)]: {
                      display: 'inline-block'
                    },
                    color: "#444444",
                    ml: 2
                  })}>
                  {userName}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Toolbar>
      <NotificationHistoryPanel open={notificationHistoryPanel} dummyNotificationHistory={{}} handleSelectJob={handleSelectedJob2} handleDeleteNotification={handleDeleteNotification} />
      <Drawer anchor="right" open={openMobileMenu} onClose={() => setOpenMobileMenu(false)}>
        <Box sx={(theme) => ({
          [theme.breakpoints.up(VIEW.PHONE)]: {
            width: '87vw'
          },
          [theme.breakpoints.up(VIEW.TABLET)]: {
            width: '350px',
          },
          height: '100vh',
          zIndex: 1100,
          backgroundColor: 'white',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          color: COLOR.black,
          ...smoothTransition
        })}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Typography variant='h5'>Menus</Typography>
            <div onClick={() => setOpenMobileMenu(false)}>
              <Close />
            </div>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            pt: '16px'
          }}>
            {routes.map((route, index) => route.children && route.children.length > 0 ? (
              <DropdownMobile key={index} label={route.label} sx={{ color: isCurrentChildRoute(route) ? "#000" : "#444444" }}>
                {route.children.map((subMenu, idx) => (
                  <Box sx={{ px: '4px', cursor: 'pointer' }} key={idx}
                    onClick={() => {
                      if (subMenu.route === '/delivery') {
                        handleDeliveryClick()
                        setOpenMobileMenu(false)
                        return
                      }
                      if (!subMenu.route) {
                        return
                      }
                      setOpenMobileMenu(false)
                      navigate(subMenu.route)
                    }}>
                    <ListItemText>{subMenu.label}</ListItemText>
                  </Box>
                ))}
              </DropdownMobile>
            ) : (
              <Typography key={index} variant="h6" sx={{ color: location.pathname.includes(route.route as string) ? "#000" : "#444444", fontSize: "14px", '&:hover': { cursor: 'pointer', color: "#4e4e4e" } }}
                onClick={() => {
                  if (!route.route) {
                    return
                  }
                  setOpenMobileMenu(false)
                  navigate(route.route)
                }}>
                {route.label}
              </Typography>
            ))}

            {<Typography
              variant="h6"
              sx={{
                color: "#440000",
                fontWeight: 500,
                mt: '12px',
                fontSize: "14px",
                '&:hover': {
                  cursor: 'pointer',
                  color: "#4e4e4e"
                }
              }}
              onClick={() => {
                logout()
              }}>
              Log out
            </Typography>}
          </Box>
        </Box>
      </Drawer>
    </AppBar>
  );
}

export default TopBar;
